import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { ILoadStatus, LoadStatusEnum } from 'features/common/LoadStatus';
import { StandardReject } from 'features/common/StandardReject';
import { RootState } from 'app/store';

import { MapClientInstance } from 'clients/MapClientInstance';
import { ToViewModel } from 'common/mapping/toViewModel';
import {
    ActionMapMpaStatus,
    AdoptTheBlueMemberType,
    AdoptTheBlueMetric,
    ActionMapGetAllRequestApiModel,
    IActionMapGetAllRequestApiModel,
    ISubmissionQuestionBulkApiModel,
    IUrlsBulkApiModel,
    ISubmissionQuestionOptionBulkApiModel,
    IActionMapFilterStatsApiModel
} from 'clients/AdoptTheBlueClient';

import { BulkClientInstance } from 'clients/BulkClientInstance';
import { DateTime } from "luxon";

export interface ISystemDataApiModel {
    submissionQuestions?: ISubmissionQuestionBulkApiModel[];
    urls?: IUrlsBulkApiModel;
}

export interface ISiteListItem {
    uId: string;
    name: string;
    lat: number;
    lon: number;
}

export interface ISiteListStatItem {
    submissionQuestionId: number;
    submissionQuestionOptionId: number;
    count: number;
}

export interface ISiteList {
    sites?: ISiteListItem[];
    stats?: ISiteListStatItem[];
    filterStats: IActionMapFilterStatsApiModel
}

export interface IDonutChartStatItem {
    submissionQuestionId: number;
    labels: string[];
    data: number[];
}

export interface ISiteDetails {
    uId: string;
    dateAdded: string;
    siteName: string;
    estimatedDiveSiteArea: number;
    estimatedDiveSiteAreaMetric: AdoptTheBlueMetric;
    lat: number;
    lon: number;
    ecosystemChosenOptions: number[];
    conservationIssueChosenOptions: number[];
    marineProtectedAreaChosenOption: ActionMapMpaStatus;
    memberType: AdoptTheBlueMemberType;
    padiMembershipOrStoreNumber: string;
    emailAddress: string;
    individualOrOrganisationName: string;
    websiteUrl?: string | undefined;
    socialMediaFacebook?: string | undefined;
    socialMediaInstagram?: string | undefined;
}

export type ActionMapState = {
    systemData?: ISystemDataApiModel | null,
    systemDataStatus: ILoadStatus,

    siteList?: ISiteList | null;
    siteListStatus: ILoadStatus,

    selectedSiteDetails?: ISiteDetails | null;
    selectedSiteDetailsStatus: ILoadStatus;

    showSiteDetailsPanel: boolean;
};

const initialState: ActionMapState = {
    systemDataStatus: {
        loadStatus: LoadStatusEnum.Idle
    },
    siteListStatus: {
        loadStatus: LoadStatusEnum.Idle
    },
    selectedSiteDetailsStatus: {
        loadStatus: LoadStatusEnum.Idle
    },
    showSiteDetailsPanel: false
};

// Thunks

export const fetchMapMarkerDetails = createAsyncThunk<ISiteDetails, string, { rejectValue: StandardReject }>
    ('actionMapSlice/fetchMapMarkerDetails', async (uId: string, { rejectWithValue }) => {

        try {
            const client = MapClientInstance.create();

            const response = await client.Instance.getById(uId);

            const ret = {
                uId: response.uId,
                dateAdded: DateTime.fromJSDate(response.dateAdded).toFormat("DDD"),
                siteName: response.siteName,
                estimatedDiveSiteArea: response.estimatedDiveSiteArea,
                estimatedDiveSiteAreaMetric: response.estimatedDiveSiteAreaMetric,
                lat: response.lat,
                lon: response.lon,
                ecosystemChosenOptions: response.ecosystemChosenOptions,
                conservationIssueChosenOptions: response.conservationIssueChosenOptions,
                marineProtectedAreaChosenOption: response.marineProtectedAreaChosenOption,
                memberType: response.memberType,
                padiMembershipOrStoreNumber: response.padiMembershipOrStoreNumber,
                emailAddress: response.emailAddress,
                individualOrOrganisationName: response.individualOrOrganisationName,
                websiteUrl: response.websiteUrl,
                socialMediaFacebook: response.socialMediaFacebook,
                socialMediaInstagram: response.socialMediaInstagram
            } as ISiteDetails;

            return ret;

        } catch (err: any) {

            return rejectWithValue({
                status: err.status,
                validationErrors: err.validationErrors,
                errorMessage: err.errorMessage
            } as StandardReject)
        }
    })

export const fetchMapMarkers = createAsyncThunk<ISiteList, IActionMapGetAllRequestApiModel, {}>('actionMapSlice/fetchMapMarkers', async (request: IActionMapGetAllRequestApiModel) => {

    const client = MapClientInstance.create();

    var response = await client.Instance.getAll(new ActionMapGetAllRequestApiModel(request));

    var ret: ISiteList = {
        sites: response.sites.map(a => {
            return {
                uId: a.uId,
                name: a.name,
                lat: a.lat,
                lon: a.lon,
            } as ISiteListItem
        }),
        stats: response.options.map(a => {
            return {
                submissionQuestionId: a.submissionQuestionId,
                submissionQuestionOptionId: a.submissionQuestionOptionId,
                count: a.count
            } as ISiteListStatItem
        }),
        filterStats: {
            isFiltered: response.filterStats.isFiltered,
            numResultsTotal: response.filterStats.numResultsTotal,
            numResultsFiltered: response.filterStats.numResultsFiltered,
            areaTotal: response.filterStats.areaTotal,
            areaFiltered: response.filterStats.areaFiltered,
        }
    };

    return ret;
})

export const fetchSystemData = createAsyncThunk<ISystemDataApiModel>('adoptTheBlueFormSlice/fetchSystemData', async () => {

    var bulkClient = BulkClientInstance.create();

    var systemData = await bulkClient.BulkClient.getSystemData();

    var ret: ISystemDataApiModel = {
        submissionQuestions: ToViewModel.SubmissionQuestionArray(systemData.submissionQuestions),
        urls: ToViewModel.Urls(systemData.urls)
    };

    return ret;
})

// Slice

const actionMapSlice = createSlice({
    name: 'userSession',
    initialState: initialState,
    reducers: {
        setShowSiteDetailsPanel: (state, action) => {
            state.showSiteDetailsPanel = action.payload;

            return state;
        }

    },
    extraReducers: builder => {
        builder
            // fetchSystemData
            .addCase(fetchSystemData.pending, (state, action) => {
                state.systemData = null;
                state.systemDataStatus = {
                    loadStatus: LoadStatusEnum.Loading
                };
            })
            .addCase(fetchSystemData.fulfilled, (state, action) => {
                state.systemData = action.payload;
                state.systemDataStatus = {
                    loadStatus: LoadStatusEnum.Completed
                };

                return state;
            })
            .addCase(fetchSystemData.rejected, (state, action) => {
                state.systemData = null;
                state.systemDataStatus = {
                    loadStatus: LoadStatusEnum.Error,
                    errorMessage: action.error.message
                };
            })

            // fetchMapMarkers
            .addCase(fetchMapMarkers.pending, (state, action) => {
                state.siteListStatus = {
                    loadStatus: LoadStatusEnum.Loading
                };
            })
            .addCase(fetchMapMarkers.fulfilled, (state, action) => {
                state.siteList = action.payload;

                state.siteListStatus = {
                    loadStatus: LoadStatusEnum.Completed
                };

                return state;
            })
            .addCase(fetchMapMarkers.rejected, (state, action) => {
                state.siteList = null;
                state.siteListStatus = {
                    loadStatus: LoadStatusEnum.Error,
                    errorMessage: action.error.message
                };
            })

            // fetchMapMarkerDetails
            .addCase(fetchMapMarkerDetails.pending, (state, action) => {
                state.selectedSiteDetails = null;
                state.selectedSiteDetailsStatus = {
                    loadStatus: LoadStatusEnum.Loading
                };

                return state;
            })
            .addCase(fetchMapMarkerDetails.fulfilled, (state, action) => {
                state.selectedSiteDetails = action.payload;
                state.selectedSiteDetailsStatus = {
                    loadStatus: LoadStatusEnum.Completed
                };

                return state;
            })
            .addCase(fetchMapMarkerDetails.rejected, (state, action) => {
                state.selectedSiteDetails = null;
                state.selectedSiteDetailsStatus = {
                    loadStatus: LoadStatusEnum.Error,
                    errorMessage: action.error.message
                };

                return state;
            })
    }
})

// Selectors

type Selector<S> = (state: RootState) => S;

export const getSubmissionQuestionByIdSelector = (id: number): Selector<ISubmissionQuestionBulkApiModel | undefined> =>
    createSelector(
        [(state: RootState) => state.actionMapSliceReducer.systemData?.submissionQuestions?.find(a => a.id === id)],
        (item) => item
    );


export const getAllSubmissionQuestionOptionsSelector = createSelector(
    (state: RootState) => state,
    (state) => {

        let ret: ISubmissionQuestionOptionBulkApiModel[] = [];

        state.actionMapSliceReducer.systemData?.submissionQuestions?.forEach((elem) => {
            elem.options.forEach(option => {
                ret.push(option);
            })
        })

        return ret;
    }
);

export const getSystemDataLoadStatusSelector = createSelector(
    (state: RootState) => state,
    (state) => {
        return {
            loadStatus: state.actionMapSliceReducer.systemDataStatus?.loadStatus,
            errorMessage: state.actionMapSliceReducer.systemDataStatus?.errorMessage
        } as ILoadStatus
    }
);

export const getSystemDataUrlsSelector = createSelector(
    (state: RootState) => state,
    (state) => state.actionMapSliceReducer.systemData?.urls
);

export const getSiteListSelector = createSelector(
    (state: RootState) => state,
    (state) => state.actionMapSliceReducer.siteList
);

export const getSiteListFilterStatsSelector = createSelector(
    (state: RootState) => state,
    (state) => state.actionMapSliceReducer.siteList?.filterStats
);

export const getSiteListStatsSelector = createSelector(
    (state: RootState) => state,
    (state) => {

        if (!state.actionMapSliceReducer.siteList?.stats ||
            !state.actionMapSliceReducer.systemData?.submissionQuestions
        ) {
            return null;
        }

        const ret = state.actionMapSliceReducer.systemData!.submissionQuestions.map(q => {

            let results = state.actionMapSliceReducer.siteList!.stats!.filter(a => a.submissionQuestionId == q.id);

            let labels: string[] = [];
            let data: number[] = [];

            results.forEach(stat => {

                const question = state.actionMapSliceReducer.systemData!.submissionQuestions!.find(a => a.id == stat.submissionQuestionId);
                const option = question!.options.find(o => o.id == stat.submissionQuestionOptionId);

                labels.push(option!.name);
                data.push(stat.count);
            })

            return {
                submissionQuestionId: q.id,
                labels: labels,
                data: data
            } as IDonutChartStatItem;
        });

        return ret;
    }
);

export const getSiteListStatusSelector = createSelector(
    (state: RootState) => state,
    (state) => state.actionMapSliceReducer.siteListStatus
);

export const getSelectedSiteDetailsSelector = createSelector(
    (state: RootState) => state,
    (state) => state.actionMapSliceReducer.selectedSiteDetails
);

export const getSelectedSiteDetailsStatusSelector = createSelector(
    (state: RootState) => state,
    (state) => state.actionMapSliceReducer.selectedSiteDetailsStatus
);

export const getShowSiteDetailsPanelSelector = createSelector(
    (state: RootState) => state,
    (state) => state.actionMapSliceReducer.showSiteDetailsPanel
);

export const {
    setShowSiteDetailsPanel
} = actionMapSlice.actions;

export default actionMapSlice.reducer;