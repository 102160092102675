import { IFormValues } from 'common/viewModels';
import { getIsDebugModeEnabledSelector } from 'features/adoptTheBlueForm/adoptTheBlueFormSlice';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';

function Debugger() {

    const { values, errors } = useFormikContext<IFormValues>();
    const isDebugModeEnabled = useSelector(getIsDebugModeEnabledSelector);

    if (!isDebugModeEnabled) {
        return null;
    }

    return (
        <pre style={{ position: "fixed", padding: "10px", top: "75px", left: "10px", width: "450px", height: "300px", background: "rgba(255, 255, 255, 0.5)", border: "1px solid #eeeeee" }}>
            <pre style={{ "background": "#eee", padding: "10px" }}>
                {JSON.stringify(values, null, 2)}
            </pre>

            <pre style={{ "background": "#eee", padding: "10px" }}>
                {JSON.stringify(errors, null, 2)}
            </pre>
        </pre>
    );
}

export default Debugger;