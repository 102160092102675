import { ActionMapClient } from './AdoptTheBlueClient';
import axios, { AxiosInstance } from 'axios';
import { ApiClientConfig } from "./ClientBase";
import { AppConfig } from 'common/config';

export class MapClientInstance {

    private constructor(
        axiosApiInstance: AxiosInstance,
        ActionMapClient: ActionMapClient,
        apiClientConfig: ApiClientConfig
    ) {
        this.AxiosApiInstance = axiosApiInstance;
        this.Instance = ActionMapClient;
        this.ApiClientConfig = apiClientConfig;
    }

    public AxiosApiInstance: AxiosInstance;
    public Instance: ActionMapClient;
    public ApiClientConfig;

    public static create(): MapClientInstance {

        var axiosApiInstance = axios.create();
        var apiClientConfig = new ApiClientConfig();

        var client = new ActionMapClient(
            apiClientConfig,
            AppConfig.Settings.apiBaseUrl,
            axiosApiInstance
        );

        let ret = new MapClientInstance(axiosApiInstance, client, apiClientConfig);

        return ret;
    }
}