import { FieldAttributes, useFormikContext } from "formik";
import ReCAPTCHA from "react-google-recaptcha";

type CustomCaptchaProps = FieldAttributes<{}> & {
    label?: string;
    sitekey?: string;
};

export function CustomCaptcha(props: CustomCaptchaProps) {

    const { setFieldValue } = useFormikContext();
    const { id, label, sitekey } = props;

    const fullId = id || `${props.name}`;

    const handleCaptchaOnChange = (token: string | null) => {
        setFieldValue(props.name, token);
    }

    const handleCaptchaOnExpired = () => {
        setFieldValue(props.name, null);
    }
    const handleCaptchaOnErrored = () => {
        setFieldValue(props.name, null);
    }

    return (
        <>
            {label && <label htmlFor={fullId} className="form-label">{label}</label>}
            <ReCAPTCHA
                sitekey={sitekey ?? ""}
                onChange={handleCaptchaOnChange}
                onExpired={handleCaptchaOnExpired}
                onErrored={handleCaptchaOnErrored}
            />
        </>
    );
}