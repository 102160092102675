import { getIn } from "formik";

export const isInvalid = (name: string, errors: any, touched: any): boolean => {

    const error = getIn(errors, name);
    const touch = getIn(touched, name);

    if (error && touch) {
        return true;
    }

    return false;
};