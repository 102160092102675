import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import ScrollToTop from 'common/components/ScrollToTop';
import { AppConfig } from 'common/config';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import { store } from 'app/store';
import { Provider } from 'react-redux';
import ConfigLoader from 'common/components/ConfigLoader';
import GoogleMapsLoader from 'common/components/GoogleMapsLoader';

ReactDOM.render(
    <ConfigLoader loading={() => <>Loading...</>} ready={() =>
        <GoogleMapsLoader loading={() => <>Loading...</>} ready={() =>
            <React.StrictMode>
                <Provider store={store}>
                    <Router basename={AppConfig.Settings.pathBase}>
                        <ScrollToTop />
                        <App />
                    </Router>
                </Provider>
            </React.StrictMode>
        } />
    } />,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();