import { BulkClient } from './AdoptTheBlueClient';
import axios, { AxiosInstance } from 'axios';
import { ApiClientConfig } from "./ClientBase";
import { AppConfig } from 'common/config';

export class BulkClientInstance {

    private constructor(
        axiosApiInstance: AxiosInstance,
        bulkClient: BulkClient,
        apiClientConfig: ApiClientConfig
    ) {
        this.AxiosApiInstance = axiosApiInstance;
        this.BulkClient = bulkClient;
        this.ApiClientConfig = apiClientConfig;
    }

    public AxiosApiInstance: AxiosInstance;
    public BulkClient: BulkClient;
    public ApiClientConfig;

    public static create(): BulkClientInstance {

        var axiosApiInstance = axios.create();
        var apiClientConfig = new ApiClientConfig();

        var client = new BulkClient(
            apiClientConfig,
            AppConfig.Settings.apiBaseUrl,
            axiosApiInstance
        );

        let ret = new BulkClientInstance(axiosApiInstance, client, apiClientConfig);

        return ret;
    }
}