import * as ApiModels from 'clients/AdoptTheBlueClient';
import * as ViewModels from 'common/viewModels';

export class ToApiModel {

    // Note: Despite number arrays being used in TypeScript, the checkboxes and other inputs on the form
    // use strings internally thus force strings into the number arrays so we must force them back again.
    static MapIntArray(data?: number[] | string[]): number[] | undefined {
        var ret = data?.map(a => parseInt(a.toString()));

        return ret;
    }

    static SubmissionQuestionArray(data?: ViewModels.ISubmissionQuestion[]): ApiModels.ISubmissionQuestionBulkApiModel[] | undefined {

        var ret = data?.map(a => {
            return {
                id: a.id,
                freeTextOptionId: a.freeTextOptionId,
                name: a.name,
                description: a.description,
                options: a.options.map(a => ToApiModel.SubmissionQuestionOption(a))
            } as ApiModels.ISubmissionQuestionBulkApiModel
        });

        return ret;
    }

    static SubmissionQuestionOption(data: ViewModels.ISubmissionQuestionOption): ApiModels.ISubmissionQuestionOptionBulkApiModel | undefined {

        var ret = {
            id: data.id,
            submissionQuestionId: data.submissionQuestionId,
            name: data.name,
            sortOrder: data.sortOrder,
        } as ApiModels.ISubmissionQuestionOptionBulkApiModel

        return ret;
    }

    static AdoptTheBlueSubmissionApiModel(data: ViewModels.IFormValues): ApiModels.IAdoptTheBlueSubmissionApiModel {

        var ret = {
            userDetails: {
                username: data.userDetails.username,
                memberType: data.userDetails.memberType,
                padiMembershipNumber: data.userDetails.padiMembershipNumber?.toString(),
                padiStoreNumber: data.userDetails.padiStoreNumber?.toString(),
                firstName: data.userDetails.firstName,
                lastName: data.userDetails.lastName,
                emailAddress: data.userDetails.emailAddress,
                organisationName: data.userDetails.organisationName,
                websiteUrl: data.userDetails.websiteUrl,
                socialMediaFacebook: data.userDetails.socialMediaFacebook,
                socialMediaInstagram: data.userDetails.socialMediaInstagram,
                address: {
                    country: data.userDetails.address.country
                },
            },
            siteDetails: {
                diveLocation: data.siteDetails.diveLocation,
                estimatedDiveSiteArea: data.siteDetails.estimatedDiveSiteArea,
                estimatedDiveSiteAreaMetric: data.siteDetails.estimatedDiveSiteAreaMetric,
                location: data.siteDetails.location,
                ecosystemChosenOptions: ToApiModel.MapIntArray(data.siteDetails.ecosystemChosenOptions),
                ecosystemOtherDetails: data.siteDetails.ecosystemOtherDetails,
                conservationIssueChosenOptions: ToApiModel.MapIntArray(data.siteDetails.conservationIssueChosenOptions),
                conservationIssueOtherDetails: data.siteDetails.conservationIssueOtherDetails,
                marineProtectedAreaChosenOption: parseInt(data.siteDetails.marineProtectedAreaChosenOption?.toString() ?? ""),
                marineProtectedAreaFurtherDetails: data.siteDetails.marineProtectedAreaFurtherDetails,
            },
            consentDetails: {
                isOptedInName: data.consentDetails.isOptedInName,
                isOptedInInstagram: data.consentDetails.isOptedInInstagram,
                isOptedInFacebook: data.consentDetails.isOptedInFacebook,
                isOptedInWebsite: data.consentDetails.isOptedInWebsite,
                isOptedInPadiOrStoreNumber: data.consentDetails.isOptedInPadiOrStoreNumber,
                isOptedInForCommunications: data.consentDetails.isOptedInForCommunications,
            },
            recaptcha: {
                recaptchaToken: data.recaptcha?.recaptchaToken
            }
        } as ApiModels.IAdoptTheBlueSubmissionApiModel

        return ret;
    }
}