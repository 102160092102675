
import { Field, FieldAttributes, useFormikContext } from "formik";
import { isInvalid } from "./helpers";
import cc from "classcat";

const getFieldClasses = (isFieldInvalid?: boolean) => {
    return cc([
        "form-check-input",
        {
            "is-invalid": isFieldInvalid
        }
    ]);
}

type CustomCheckboxProps = FieldAttributes<{}> & {
    label?: string;
};

export function CustomCheckbox(props: CustomCheckboxProps) {

    const { id, style, value, label, ...rest } = props;

    const { errors, touched } = useFormikContext();

    const fullId = id || `${props.name}_${props.value}`;

    return (
        <>
            <div className="form-check">
                <Field type="checkbox" id={fullId} value={value} className={getFieldClasses(isInvalid(props.name, errors, touched))} {...rest} />
                <label className="form-check-label" htmlFor={fullId}>
                    {label}
                </label>
            </div>
        </>
    );
}