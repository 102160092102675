import L from "leaflet";

export const MarkerIcon = L.divIcon({
    className: 'padi-icon map-marker',
    iconSize: [33, 46],
    iconAnchor: [16.5, 46],
    popupAnchor: [0, -50]
});

export const MarkerIconSelected = L.divIcon({
    className: 'padi-icon map-marker-selected',
    iconSize: [41, 56],
    iconAnchor: [41 / 2, 51],
    popupAnchor: [0, -50]
});

export const MarkerClusterIcon = (numMarkers: number) => {
    let className = "";
    if (numMarkers > 1000) {
        className = "smaller";
    } else if (numMarkers > 100) {
        className = "small";
    } else {
        className = "standard"
    }

    return L.divIcon({
        html: `
        <div class="map-cluster-count-container">
            <div class="${className}">${numMarkers}</div>
        </div>
        `,
        className: 'padi-icon map-marker-cluster',
        iconSize: [50, 46],
        iconAnchor: [25, 46]
    });
}