import { CustomCheckbox } from 'common/components/CustomCheckbox';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import {
    getRequestToAdoptTheBlueStatusSelector,
    getSubmissionQuestionByIdSelector,
    getSystemDataUrlsSelector,
} from 'features/adoptTheBlueForm/adoptTheBlueFormSlice';
import { AdoptTheBlueMetaDataTypeIds, IFormValues } from 'common/viewModels';
import { CustomTextbox } from 'common/components/CustomTextbox';
import { CustomTextarea } from 'common/components/CustomTextarea';
import { ISubmissionQuestionBulkApiModel, ISubmissionQuestionOptionBulkApiModel } from 'clients/AdoptTheBlueClient';
import CustomErrorMessage from 'common/components/CustomErrorMessage';
import { CustomRadioButton } from 'common/components/CustomRadioButton';
import { getFullNameOrOrgName, getPadiMembershipOrStoreNumber } from 'common/helpers';
import { CustomCaptcha } from 'common/components/CustomCaptcha';
import { AppConfig } from 'common/config';
import { useEffect } from 'react';

function Step3() {

    const { values, setFieldValue } = useFormikContext<IFormValues>();

    const getSystemDataUrls = useSelector(getSystemDataUrlsSelector);

    const ecosystemQuestion = useSelector(getSubmissionQuestionByIdSelector(AdoptTheBlueMetaDataTypeIds.Ecosystem));
    const conservationIssuesQuestion = useSelector(getSubmissionQuestionByIdSelector(AdoptTheBlueMetaDataTypeIds.ConservationIssues));
    const marineProtectedOptionQuestion = useSelector(getSubmissionQuestionByIdSelector(AdoptTheBlueMetaDataTypeIds.MarineProtected));
    const requestToAdoptTheBlueStatus = useSelector(getRequestToAdoptTheBlueStatusSelector);

    type PermissiveHtmlId = number[] | number | string[] | string;

    const isFreeTextOptionSelected = (question?: ISubmissionQuestionBulkApiModel, chosenOptions?: PermissiveHtmlId): boolean => {

        var chosenOptionsArr = (chosenOptions instanceof Array) ? chosenOptions : [chosenOptions];

        if (!chosenOptionsArr?.length || !question?.freeTextOptionId) {
            return false;
        }

        if (chosenOptionsArr.some(a => a === question.freeTextOptionId?.toString())) {
            return true;
        }

        return false;
    };

    enum ExclusiveOptionStatus {
        NotSelected = 1,
        SelectedThis = 2,
        SelectedOther = 3
    }

    const getExclusiveOptionStatus = (question?: ISubmissionQuestionBulkApiModel, chosenOptions?: PermissiveHtmlId, currentOption?: ISubmissionQuestionOptionBulkApiModel): ExclusiveOptionStatus => {

        var chosenOptionsArr = (chosenOptions instanceof Array) ? chosenOptions : [chosenOptions];

        if (!question?.exclusiveOptionId || !currentOption) {
            return ExclusiveOptionStatus.NotSelected;
        }

        if (chosenOptionsArr.some(a => a?.toString() === question.exclusiveOptionId?.toString())) {

            if (currentOption.id.toString() === question.exclusiveOptionId?.toString()) {
                return ExclusiveOptionStatus.SelectedThis;
            } else {
                return ExclusiveOptionStatus.SelectedOther;
            }

        }

        return ExclusiveOptionStatus.NotSelected;
    }

    useEffect(() => {

        // Do nothing if we have nothing to work with
        if (!values.siteDetails.conservationIssueChosenOptions || !conservationIssuesQuestion?.exclusiveOptionId) {
            return;
        }

        const newValues = [conservationIssuesQuestion.exclusiveOptionId!.toString()];

        // If the collection already contains the "exlusive" item, do nothing
        if (values.siteDetails.conservationIssueChosenOptions.length === 1 && values.siteDetails.conservationIssueChosenOptions[0] === newValues[0]) {
            return;
        }

        // If the collection contains the exclusive item, replace everything with just the exclusive item
        if (values.siteDetails.conservationIssueChosenOptions.some(a => a.toString() === conservationIssuesQuestion.exclusiveOptionId!.toString())) {
            setFieldValue("siteDetails.conservationIssueChosenOptions", newValues);
        }

    }, [values.siteDetails.conservationIssueChosenOptions])

    return (
        <>
            <div className="mb-5">

                <p className="fw-bold">What Ecosystem is the dive site? <span className="text-danger">*</span></p>

                {ecosystemQuestion?.options?.map((p, i) =>
                    <CustomCheckbox key={`submissionQuestion_${p.id}`}
                        name={`siteDetails.ecosystemChosenOptions`}
                        value={p.id.toString()}
                        label={p.name}
                    />
                )}

                <CustomErrorMessage name="siteDetails.ecosystemChosenOptions" />

                {isFreeTextOptionSelected(ecosystemQuestion, values.siteDetails.ecosystemChosenOptions) &&
                    <div className="my-4 row">
                        <div className='col'>
                            <CustomTextbox name="siteDetails.ecosystemOtherDetails" placeholder="Other" />
                        </div>
                        <div className="col d-none d-sm-block">
                            &nbsp;
                        </div>
                    </div>
                }
            </div>

            <div className="mb-5">

                <p className="fw-bold">What (if any) conservation issues is this dive site facing? <span className="text-danger">*</span></p>

                {conservationIssuesQuestion?.options?.map((p, i) =>
                    <CustomCheckbox key={`submissionQuestion_${p.id}`}
                        name={`siteDetails.conservationIssueChosenOptions`}
                        value={p.id.toString()}
                        label={p.name}
                        disabled={getExclusiveOptionStatus(conservationIssuesQuestion, values.siteDetails.conservationIssueChosenOptions, p) === ExclusiveOptionStatus.SelectedOther}
                    />
                )}

                <CustomErrorMessage name="siteDetails.conservationIssueChosenOptions" />

                {isFreeTextOptionSelected(conservationIssuesQuestion, values.siteDetails.conservationIssueChosenOptions) &&
                    <div className="my-4 row">
                        <div className='col'>
                            <CustomTextbox name="siteDetails.conservationIssueOtherDetails" placeholder="Other" />
                        </div>
                        <div className="col d-none d-sm-block">
                            &nbsp;
                        </div>
                    </div>
                }
            </div>

            <div className="mb-5">

                <p className="fw-bold">Please check the following in relation to Marine Protected Areas/Marine Reserves at your dive site: <span className="text-danger">*</span></p>

                {marineProtectedOptionQuestion?.options?.map((p, i) =>
                    <CustomRadioButton
                        key={`submissionQuestion_${p.id}`}
                        name={`siteDetails.marineProtectedAreaChosenOption`}
                        value={p.id.toString()}
                        label={p.name}
                    />
                )}

                <CustomErrorMessage name="siteDetails.marineProtectedAreaChosenOption" />

                {isFreeTextOptionSelected(marineProtectedOptionQuestion, values.siteDetails.marineProtectedAreaChosenOption) &&
                    <div className="my-4">
                        <p className="fw-bold">Please provide any further relevant details on your answer above <span className="text-danger">*</span></p>

                        <CustomTextarea name="siteDetails.marineProtectedAreaFurtherDetails" placeholder="Further details" rows={5} />
                    </div>
                }
            </div>

            <br />

            <div className="mb-5">

                <p className="fw-bold">Your data: Contact with PADI <span className="text-danger">*</span></p>

                <p>The primary function of Adopt the Blue is to create a network of PADI Dive Centers and Professionals committed to ocean protection that may, at times, be contacted by PADI AWARE Foundation™ in order to mobilize local conservation activities.</p>

                <p>
                    <CustomCheckbox
                        name={`isUserConfirmed`}
                        label="I agree that PADI AWARE Foundation™ and PADI® are partners and I give my permission to be contacted by either entity regarding conservation actions.  For citizens of China, the account information I submit will be processed outside of China."
                    />
                    <CustomErrorMessage name="isUserConfirmed" />
                </p>

                <p>Click here for more information regarding our <a href={getSystemDataUrls?.privacyPolicy} target="_blank" rel="noreferrer">Privacy Policy</a>.</p>

            </div>

            <div className="mb-5">

                <p className="fw-bold">Your data: Contact with Consumers (Optional)</p>

                <p>I agree that PADI AWARE Foundation™ &amp; PADI® can display the following information publicly in reference to my participation in Adopt The Blue.</p>

                <p>
                    <CustomCheckbox name={`consentDetails.isOptedInName`} label={`Name: ${values.userDetails?.firstName?.trim()} ${getFullNameOrOrgName(values)}`} />
                    {values.userDetails?.socialMediaInstagram && <CustomCheckbox name={`consentDetails.isOptedInInstagram`} label={`Instagram: @${values.userDetails?.socialMediaInstagram}`} />}
                    {values.userDetails?.socialMediaFacebook && <CustomCheckbox name={`consentDetails.isOptedInFacebook`} label={`Facebook: @${values.userDetails?.socialMediaFacebook}`} />}
                    {values.userDetails?.websiteUrl && <CustomCheckbox name={`consentDetails.isOptedInWebsite`} label={`Website: ${values.userDetails?.websiteUrl}`} />}
                    <CustomCheckbox name={`consentDetails.isOptedInPadiOrStoreNumber`} label={`PADI Member or Store Number: ${getPadiMembershipOrStoreNumber(values)}`} />
                </p>

                <p className="mt-4">
                    <CustomCheckbox name={`consentDetails.isOptedInForCommunications`} label="I would like to be contacted by consumers who want to get involved with our conservation activities" />
                </p>

                <p className="my-4">
                    <CustomCaptcha name="recaptcha.recaptchaToken" sitekey={AppConfig.Settings.reCaptchaApiKey} />
                    <CustomErrorMessage name="recaptcha.recaptchaToken" />
                </p>

                {requestToAdoptTheBlueStatus.validationErrors &&
                    <div className="alert alert-warning" role="alert">
                        <p className="">Please correct the following and try again:</p>

                        <ul>
                            {requestToAdoptTheBlueStatus.validationErrors?.map(a =>
                                <li>
                                    {a.errors.map(a => a)}
                                </li>
                            )}
                        </ul>
                    </div>
                }
            </div>
        </>
    );
}

export default Step3;