import { useEffect, useState } from 'react';
import { Config } from "common/config";

interface IConfigLoaderProps {
    ready: () => JSX.Element;
    loading?: () => JSX.Element;
}

function ConfigLoader(props: IConfigLoaderProps) {

    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {

        async function loadConfig() {
            await Config.load();

            setIsLoaded(true);
        }

        loadConfig();            
    }, []);

    if (!isLoaded) {
        return props.loading ? props.loading() : <>Loading...</>;
    }

    return props.ready();
}

export default ConfigLoader;