import CustomNumberField from "common/components/CustomNumberField";
import { IFormValues } from "common/viewModels";
import { useFormikContext } from "formik";
import { LatLng } from "leaflet";
import { useState, useEffect } from "react";
import MapCoordSelector from "./MapCoordSelector";

interface MapCoordSelectorFormProps {
    defaultLocation: LatLng;
}

function MapCoordSelectorForm({ defaultLocation }: MapCoordSelectorFormProps) {

    const [location, setLocation] = useState<LatLng>(defaultLocation);
    const formContext = useFormikContext<IFormValues>();

    useEffect(() => {
        if (formContext.values.siteDetails.location?.latitude !== undefined && formContext.values.siteDetails.location?.longitude !== undefined) {
            setLocation(new LatLng(formContext.values.siteDetails.location.latitude, formContext.values.siteDetails.location.longitude));
        }
    }, [formContext.values.siteDetails.location])

    function handleOnCoordinatesSelected(coords: LatLng) {
        formContext.setFieldValue("siteDetails.location.latitude", coords.lat);
        formContext.setFieldValue("siteDetails.location.longitude", coords.lng);
        setLocation(coords);
    }

    return <div>
        <MapCoordSelector onCoordinatesSelected={handleOnCoordinatesSelected} location={location} />

        <div className="my-3">
            <div className="d-flex flex-md-row flex-column gap-3">
                <CustomNumberField name="siteDetails.location.latitude" placeholder="Latitude" />
                <CustomNumberField name="siteDetails.location.longitude" placeholder="Longitude" />
            </div>
        </div>

    </div>;
}

export default MapCoordSelectorForm;