import * as ApiModels from 'clients/AdoptTheBlueClient';
import * as ViewModels from 'common/viewModels';

export class ToViewModel {

    static SubmissionQuestionArray(data?: ApiModels.ISubmissionQuestionBulkApiModel[]): ViewModels.ISubmissionQuestion[] | undefined {

        var ret = data?.map(a => ToViewModel.SubmissionQuestion(a));

        return ret;
    }

    static SubmissionQuestion(data: ApiModels.ISubmissionQuestionBulkApiModel): ViewModels.ISubmissionQuestion {

        var options = data.options
            .sort((a, b) => a.sortOrder - b.sortOrder)
            .map(a => ToViewModel.SubmissionQuestionOption(a));

        var ret = {
            id: data.id,
            freeTextOptionId: data.freeTextOptionId,
            exclusiveOptionId: data.exclusiveOptionId,
            name: data.name,
            description: data.description,
            options: options
        } as ViewModels.ISubmissionQuestion

        return ret;
    }

    static SubmissionQuestionOption(data: ApiModels.ISubmissionQuestionOptionBulkApiModel): ViewModels.ISubmissionQuestionOption | undefined {

        var ret = {
            id: data.id,
            submissionQuestionId: data.submissionQuestionId,
            name: data.name,
            sortOrder: data.sortOrder,
        } as ViewModels.ISubmissionQuestionOption

        return ret;
    }

    static Urls(data: ApiModels.IUrlsBulkApiModel): ViewModels.IUrlsBulkApiModel | undefined {

        var ret = {
            actionMap: data.actionMap,
            adoptTheBlueFAQs: data.adoptTheBlueFAQs,
            adoptTheBlueToolkit: data.adoptTheBlueToolkit,
            currentActions: data.currentActions,
            diveAgainstDebrisToolkit: data.diveAgainstDebrisToolkit,
            makeADonation: data.makeADonation,
            ourConservationStrategy: data.ourConservationStrategy,
            privacyPolicy: data.privacyPolicy,
            whyParticipate: data.whyParticipate
        } as ViewModels.IUrlsBulkApiModel

        return ret;
    }
}