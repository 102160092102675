import { Field, FieldAttributes, useFormikContext } from "formik";
import cc from "classcat";
import { isInvalid } from "./helpers";

type CustomNumberFieldProps = FieldAttributes<{}> & {
    label?: string;
};

const CustomBlurOnMouseWheelInput = ({ field, form, ...props }: { field: any, form: any }) => {
    return <input {...field} {...props} onWheel={(e) => e.currentTarget.blur()} />;
};

const getFieldClasses = (isFieldInvalid?: boolean) => {
    return cc([
        "form-control",
        {
            "is-invalid": isFieldInvalid
        }
    ]);
}

function CustomNumberField(props: CustomNumberFieldProps) {

    const { errors, touched } = useFormikContext();
    const { type, label, ...rest } = props;
    const fullId = props.id || `${props.name}`;

    return (
        <>
            {label && <label htmlFor={fullId} className="form-label">{label}</label>}
            <Field type={"number"} className={getFieldClasses(isInvalid(props.name, errors, touched))} component={CustomBlurOnMouseWheelInput} {...rest} />
        </>
    );
}

export default CustomNumberField;