export const IconMapMarker = () => <span className='padi-icon map-marker'></span>;
export const IconMapMarkerSelected = () => <span className='padi-icon map-marker-selected'></span>
export const IconMapMarkerCluster = () => <span className='padi-icon map-marker-cluster'></span>

export const IconContactEmail = () => <span className='padi-icon contact-email'></span>
export const IconContactFacebook = () => <span className='padi-icon contact-facebook'></span>
export const IconContactInstagram = () => <span className='padi-icon contact-instagram'></span>
export const IconContactWebsite = () => <span className='padi-icon contact-website'></span>

export const IconGeneralClose = () => <span className='padi-icon general-close'></span>
export const IconGeneralCloseSolid = () => <span className='padi-icon general-close-solid'></span>
export const IconGeneralConservationIssues = () => <span className='padi-icon general-conservation-issues'></span>
export const IconGeneralDataOptions = () => <span className='padi-icon general-data-options'></span>
export const IconGeneralDate = () => <span className='padi-icon general-date'></span>
export const IconGeneralMemberIndividual = () => <span className='padi-icon general-member-individual'></span>
export const IconGeneralMemberBusiness = () => <span className='padi-icon general-member-business'></span>
export const IconGeneralEcosystem = () => <span className='padi-icon general-ecosystem'></span>
export const IconGeneralFilterCollapsed = () => <span className='padi-icon general-filter-collapsed'></span>
export const IconGeneralMarker = () => <span className='padi-icon general-marker'></span>

export const IconGeneralMpaUnprotected = () => <span className='padi-icon general-mpa-unprotected'></span>
export const IconGeneralMpaPending = () => <span className='padi-icon general-mpa-pending'></span>
export const IconGeneralMpaUnknown = () => <span className='padi-icon general-mpa-unknown'></span>
export const IconGeneralMpaProtected = () => <span className='padi-icon general-mpa-protected'></span>