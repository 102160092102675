import styled from 'styled-components';
import PopupContainer from '../PopupContainer';
import { ContactIcon } from './ContactIcon';
import {
    IconContactEmail,
    IconContactFacebook,
    IconContactInstagram,
    IconContactWebsite,
    IconGeneralConservationIssues,
    IconGeneralMarker,
    IconGeneralDate,
    IconGeneralMemberIndividual,
    IconGeneralMemberBusiness,
    IconGeneralEcosystem,
    IconGeneralMpaUnprotected,
    IconGeneralMpaPending,
    IconGeneralMpaUnknown,
    IconGeneralMpaProtected,
    IconGeneralClose,
} from '../Icons';
import { AdoptTheBlueMemberType, ActionMapMpaStatus } from 'clients/AdoptTheBlueClient';
import { useMobileMediaQuery } from "common/responsiveHelpers";

interface MarkerPopUpDetailsProps {
    title: string;
    lat: number;
    lng: number;

    name: string;
    memberType: AdoptTheBlueMemberType;
    dateAdded: string;

    chosenEcosystems: string[];
    chosenConservationIssues: string[];
    chosenMarineProtectionStatus: ActionMapMpaStatus;

    contactEmail?: string;
    contactWebsite?: string;
    contactInstagram?: string;
    contactFacebook?: string;

    onClose: () => void;
}

const MarkerPopUpDetailsInner = styled.div<{ width?: number }>`
    width: ${(props) => props.width ? `${props.width}px` : "auto"};
`;

const HorizontalRule = styled.hr`
    border: #CED4DA
`;

const MarkerPopUpDetailsSection = styled.div<{ width?: number }>`
    width: ${(props) => props.width ? `${props.width}px` : "auto"};
`;

const Title = styled.div`
    color: ${(props) => props.theme.colors.grayDarkest};
    font-size: 16px;
    line-height: 24px;
`;

const SubTitle = styled.div`
    color: ${(props) => props.theme.colors.grayDarker};
    font-size: 16px;
    line-height: 24px;
`;

const getMemberTypeDisplayName = (memberType: AdoptTheBlueMemberType) => {
    switch (memberType) {
        case AdoptTheBlueMemberType.Individual:
            return "Individual PADI Member";
        case AdoptTheBlueMemberType.Business:
            return "Business PADI Member";
    }

    return "";
};

const getMemberTypeIcon = (memberType: AdoptTheBlueMemberType) => memberType === AdoptTheBlueMemberType.Business ?
    <IconGeneralMemberBusiness /> :
    <IconGeneralMemberIndividual />;

const getMpaText = (mpaStatus: ActionMapMpaStatus) => {
    switch (mpaStatus) {
        case ActionMapMpaStatus.Pending:
            return "Pending";
        case ActionMapMpaStatus.Protected:
            return "Marine Protected Area";
        case ActionMapMpaStatus.Unprotected:
            return "Not a Marine Protected Area";
    }

    return "Unknown Status";
}

const getMpaIcon = (mpaStatus: ActionMapMpaStatus) => {
    switch (mpaStatus) {
        case ActionMapMpaStatus.Pending:
            return <IconGeneralMpaPending />;
        case ActionMapMpaStatus.Protected:
            return <IconGeneralMpaProtected />
        case ActionMapMpaStatus.Unprotected:
            return <IconGeneralMpaUnprotected />;
    }

    return <IconGeneralMpaUnknown />
}

function TitleSection({ title, onClose, children }: { title: string, onClose: () => void, children: React.ReactNode }) {
    return (
        <div className="py-2">
            <div className="d-flex justify-content-between align-items-center px-4">
                <p className="fw-bold py-2 m-0 fs-4">{title}</p>
                <span className="py-2" onClick={() => onClose()} style={{ cursor: "pointer" }}>
                    <IconGeneralClose />
                </span>
            </div>
            <div className="d-flex p-0 m-0">
                <div className="px-4">
                    <IconGeneralMarker />
                </div>
                <Title>
                    {children}
                </Title>
            </div>
        </div>
    );
}

function SubSection({ title, icon, children }: {
    title: string;
    icon?: React.ReactNode,
    children?: React.ReactNode;
}) {
    return (
        <div className="d-flex flex-row py-2">
            <div className="px-4">
                {!icon ? <IconGeneralMarker /> : icon}
            </div>
            <div className="pe-4">
                <Title>{title}</Title>
                {children &&
                    <SubTitle>
                        {children}
                    </SubTitle>
                }
            </div>
        </div>
    );
}

function MarkerPopUpDetails(props: MarkerPopUpDetailsProps) {

    const isMobileResponsive = useMobileMediaQuery();

    return (
        <PopupContainer>
            <MarkerPopUpDetailsInner>
                <div style={{
                    width: isMobileResponsive ? "calc(100vw - 20px)" : "360px",
                    maxHeight: isMobileResponsive ? "calc(100vh - 100px)" : "calc(100vh - 206px)",
                    overflowY: "auto"
                }}>
                    {/* Header Section */}
                    <MarkerPopUpDetailsSection>
                        <TitleSection title={props.title} onClose={props.onClose}>
                            <div>{props.lat.toFixed(6)}, {props.lng.toFixed(6)}</div>
                        </TitleSection>
                    </MarkerPopUpDetailsSection>
                    <HorizontalRule />

                    {/* Details Section */}
                    <MarkerPopUpDetailsSection>

                        <SubSection title={props.name} icon={getMemberTypeIcon(props.memberType)}>
                            <div>{getMemberTypeDisplayName(props.memberType)}</div>
                        </SubSection>

                        <SubSection title={"Date Adopted"} icon={<IconGeneralDate />}>
                            <div>{props.dateAdded}</div>
                        </SubSection>

                        <SubSection title={"Ecosystem"} icon={<IconGeneralEcosystem />}>
                            <div>
                                {props.chosenEcosystems?.join(" · ") ?? "None"}
                            </div>
                        </SubSection>

                        <SubSection title={"Conservation Issues"} icon={<IconGeneralConservationIssues />}>
                            <div>
                                {props.chosenConservationIssues.join(" · ") ?? "None"}
                            </div>
                        </SubSection>

                        <SubSection title={getMpaText(props.chosenMarineProtectionStatus)} icon={getMpaIcon(props.chosenMarineProtectionStatus)} />

                    </MarkerPopUpDetailsSection>
                    <HorizontalRule />

                    {/* Contact Section */}
                    <MarkerPopUpDetailsSection>
                        <div className="d-flex justify-content-start px-4 py-3 pb-5 gap-4">
                            {props.contactEmail && <ContactIcon name="Email" link={`mailto:${props.contactEmail}`} icon={<IconContactEmail />} />}
                            {props.contactWebsite && <ContactIcon name="Website" link={props.contactWebsite} icon={<IconContactWebsite />} />}
                            {props.contactInstagram && <ContactIcon name="Instagram" link={`https://www.instagram.com/${props.contactInstagram}`} icon={<IconContactInstagram />} />}
                            {props.contactFacebook && <ContactIcon name="Facebook" link={`https://www.facebook.com/${props.contactFacebook}`} icon={<IconContactFacebook />} />}
                        </div>
                    </MarkerPopUpDetailsSection>
                </div>
            </MarkerPopUpDetailsInner>
        </PopupContainer>
    );
}

export default MarkerPopUpDetails;