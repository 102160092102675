import { CustomRadioButton } from 'common/components/CustomRadioButton';
import { AdoptTheBlueMemberType } from 'clients/AdoptTheBlueClient';
import { useFormikContext } from 'formik';
import { IFormValues } from 'common/viewModels';
import CustomErrorMessage from 'common/components/CustomErrorMessage';

import { getSystemDataUrlsSelector } from 'features/adoptTheBlueForm/adoptTheBlueFormSlice';
import { useSelector } from 'react-redux';
import ExternalLinkIcon from 'common/icons/ExternalLinkIcon';
import CustomNumberField from 'common/components/CustomNumberField';

function Step0() {

    const getSystemDataUrls = useSelector(getSystemDataUrlsSelector);
    const { values } = useFormikContext<IFormValues>();

    return (
        <>
            <p className="fw-bold">PADI Professionals</p>

            <p>To Adopt The Blue, you will need to be a PADI member</p>

            <div className="mb-3">
                <CustomRadioButton name={`userDetails.memberType`} value={AdoptTheBlueMemberType.Individual} label="I am an individual PADI Member" />
                <CustomRadioButton name={`userDetails.memberType`} value={AdoptTheBlueMemberType.Business} label="I am a PADI dive center or resort" />
                <CustomRadioButton name={`userDetails.memberType`} value={AdoptTheBlueMemberType.Unknown} label="I am neither of the above" />
                {values.userDetails.memberType !== AdoptTheBlueMemberType.Unknown && <CustomErrorMessage name="userDetails.memberType" />}
            </div>

            {values.userDetails?.memberType === AdoptTheBlueMemberType.Individual &&
                <div className="mb-3">
                    <div className="input-group">
                        <span className="input-group-text">#</span>
                        <CustomNumberField name="userDetails.padiMembershipNumber" placeholder="PADI Member Number" />
                    </div>
                    <CustomErrorMessage name="userDetails.padiMembershipNumber" />
                </div>
            }

            {values.userDetails?.memberType === AdoptTheBlueMemberType.Business &&
                <div className="mb-3">
                    <div className="input-group">
                        <span className="input-group-text">S</span>
                        <CustomNumberField name="userDetails.padiStoreNumber" placeholder="PADI Store Number" />
                    </div>
                    <CustomErrorMessage name="userDetails.padiStoreNumber" />
                </div>
            }

            {values.userDetails?.memberType === AdoptTheBlueMemberType.Unknown &&
                <>
                    <p>
                        If you are not a PADI Member you can still make a difference by supporting our work
                    </p>

                    <p>
                        <a href={getSystemDataUrls?.makeADonation} target="_blank" rel="noreferrer">Make a donation <ExternalLinkIcon /></a>
                    </p>
                </>
            }

        </>
    );
}

export default Step0;