import L from "leaflet";

export class CustomPopup extends L.Popup {
    constructor(name: string, lat: number, lng: number) {
        super({ className: "custom-popup" });

        this.setContent(`
            <div class="px-4 py-3">
                <p class="fw-bold py-2">${name}</p>
                <div class="d-flex gap-3 py-2">
                    <div class="padi-icon general-marker"></div>
                    <div>${lat.toFixed(6)}, ${lng.toFixed(6)}</div>
                </div>
            </div>
        `);
    }
}