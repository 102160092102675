export function getBrowserLanguages() {
    try {
        const browserLocales = navigator.languages === undefined
            ? [navigator.language]
            : navigator.languages;

        if (!browserLocales || !browserLocales.length) {
            return undefined;
        }

        var mappedLocales = browserLocales.map(locale => {
            const ret = locale.trim();

            return ret.split(/-|_/)[0];
        });

        return Array.from(new Set(mappedLocales));
    } catch (error) {
        console.error("No browser language available");
    }
}

export function getPrimaryBrowserLanguage() {
    const browserLanguages = getBrowserLanguages();

    return browserLanguages && browserLanguages.length
        ? browserLanguages[0]
        : undefined;
}