import { combineReducers, configureStore } from '@reduxjs/toolkit'
//import { persistReducer } from 'redux-persist'
//import storageSession from 'redux-persist/lib/storage/session'

import adoptTheBlueReducer from 'features/adoptTheBlueForm/adoptTheBlueFormSlice';
import actionMapSliceReducer from 'features/actionMap/actionMapSlice';

// const persistConfig = {
//     key: 'root',
//     storage: storageSession
// };

const reducers = combineReducers({
    adoptTheBlueReducer: adoptTheBlueReducer,
    actionMapSliceReducer: actionMapSliceReducer
});

// const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
    reducer: reducers,
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch