import { Routes, Route } from "react-router-dom";

import Form from 'pages/Form/Index';
import Map from 'pages/Map/Index';
import MapDebugger from 'pages/Map/Debugger';
import NotFound from 'pages/NotFound/Index';
import Header from 'layout/components/Header';
import useVH from 'react-viewport-height';

import './App.css';

function App() {
    
    useVH();

    return (
        <div className="app">
            <Header />

            <Routes>
                <Route path="/adoptnow" element={<Form />} />
                {/* <Route path="/adoptnow/debugger" element={<Debugger />} /> */}

                <Route path="/actionmap" element={<Map />} />
                <Route path="/actionmap/debugger" element={<MapDebugger />} />

                <Route path="/*" element={<NotFound />} />
            </Routes>
        </div>
    );
}

export default App;