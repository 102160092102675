import { Chart as ChartJS, ArcElement, Tooltip, Legend, ChartData } from 'chart.js';
import { useCallback, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';

const messageNoData = "No data";

ChartJS.register(ArcElement, Tooltip, Legend);

interface CustomDonutChartProps {
    labels?: string[],
    data: number[],
    backgroundColors: string[];
}

const getMapFilterSidebarInner = () => document.getElementsByClassName("mapFilterSidebarInner")[0];
const getCurrentScrollTop = () => getMapFilterSidebarInner().scrollTop;

const getOrCreateTooltip = (chart: any) => {
    let tooltipEl: HTMLDivElement = chart.canvas.parentNode.querySelector('div');

    if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.classList.add("custom-chart-tooltip");

        const table = document.createElement('table');
        table.style.margin = '0px';

        tooltipEl.appendChild(table);
        chart.canvas.parentNode.appendChild(tooltipEl);
    }

    return tooltipEl;
};

const externalTooltipHandler = (context: any) => {
    // Tooltip Element
    const { chart, tooltip } = context;
    const tooltipEl = getOrCreateTooltip(chart);

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
        tooltipEl.style.opacity = "0";
        return;
    }

    // Set Text
    if (tooltip.body) {
        const titleLines = tooltip.title || [];
        const bodyLines = tooltip.body.map((b: any) => b.lines);

        const tableHead = document.createElement('thead');

        titleLines.forEach((title: any) => {
            const tr = document.createElement('tr');
            tr.style.borderWidth = "0px";

            const th = document.createElement('th');
            th.style.borderWidth = "0px";
            const text = document.createTextNode(title);

            th.appendChild(text);
            tr.appendChild(th);
            tableHead.appendChild(tr);
        });

        const tableBody = document.createElement('tbody');

        bodyLines.forEach((body: any, i: number) => {

            const colors = tooltip.labelColors[i];

            const span = document.createElement('span');
            span.classList.add("color-globe");
            span.style.background = colors.backgroundColor;
            span.style.borderColor = colors.borderColor;

            const tr = document.createElement('tr');
            tr.style.backgroundColor = 'inherit';
            tr.style.borderWidth = "0px";

            const td = document.createElement('td');
            td.style.borderWidth = "0px";

            const spanLabel = document.createElement('span'); // document.createTextNode();
            spanLabel.classList.add("custom-title-label");
            spanLabel.innerText = body[0];

            const spanNumSites = document.createElement('span');
            spanNumSites.classList.add("custom-num-sites");
            spanNumSites.innerText = `${body[1]} sites`;


            const container = document.createElement('div');
            container.classList.add("text-container");

            container.appendChild(span);
            container.appendChild(spanLabel);
            container.appendChild(spanNumSites);

            td.appendChild(container);

            tr.appendChild(td);
            tableBody.appendChild(tr);
        });

        const tableRoot = tooltipEl.querySelector('table');

        // Remove old children
        while (tableRoot?.firstChild) {
            tableRoot.firstChild.remove();
        }

        // Add new children
        tableRoot?.appendChild(tableHead);
        tableRoot?.appendChild(tableBody);
    }

    // Display, position, and set styles for font
    tooltipEl.style.opacity = "1.0";
    tooltipEl.style.left = chart.canvas.offsetLeft + tooltip.caretX + 'px';
    tooltipEl.style.top = -getCurrentScrollTop() + chart.canvas.offsetTop + tooltip.caretY + 'px';
    tooltipEl.style.font = tooltip.options.bodyFont.string;
};

const plugineEmptyDoughnut = {
    id: 'emptyDoughnut',
    afterDraw(chart: any, args: any, options: any) {
        const { datasets } = chart.data;
        const { color, width } = options;
        let hasData = false;

        for (let i = 0; i < datasets.length; i += 1) {
            if (datasets[i].data.length > 0) {
                hasData = true;
            }
        }

        if (!hasData) {
            const { chartArea: { left, top, right, bottom }, ctx } = chart;
            const centerX = (left + right) / 2;
            const centerY = (top + bottom) / 2;
            const r = Math.floor(Math.min(right - left, bottom - top) / 2) - 1;

            ctx.beginPath();
            ctx.lineWidth = width || 2;
            ctx.strokeStyle = color || 'rgba(0, 0, 0, 0.1)';
            ctx.arc(centerX, centerY, r, 0, 2 * Math.PI);
            ctx.stroke();

            ctx.textBaseline = 'middle';
            ctx.textAlign = 'center';
            ctx.font = "12px arial";
            ctx.fillStyle = '#999';
            ctx.fillText(messageNoData, centerX, centerY);
        }
    }
};

function CustomDonutChart(props: CustomDonutChartProps) {

    // We look to immediately hide (through setting opacity to zero) any 'custom-chart-tooltip' containers
    // primarily to hide the fact that they don't move with the scrollbar
    const handleMapFilterSidebarInnerScroll = useCallback(event => {
        var elems = Array.from(document.getElementsByClassName('custom-chart-tooltip') as HTMLCollectionOf<HTMLElement>);

        elems.forEach(element => {
            element.style.opacity = "0";
        });
    }, []);

    useEffect(() => {
        getMapFilterSidebarInner().addEventListener('scroll', handleMapFilterSidebarInnerScroll);

        return () => {
            getMapFilterSidebarInner().removeEventListener('scroll', handleMapFilterSidebarInnerScroll);
        };
    }, [handleMapFilterSidebarInnerScroll]);

    const chartData: ChartData<"doughnut", number[], string> = {
        labels: props.labels,
        datasets: [
            {
                label: '',
                data: props.data,
                backgroundColor: props.backgroundColors,
                borderColor: [
                    'rgba(255, 255, 255, 0.3)'
                ],
                borderWidth: 3,
            }
        ],
    };

    return <Doughnut
        data={chartData}
        options={{
            responsive: true,
            maintainAspectRatio: true,
            plugins: {
                legend: {
                    display: false
                },
                tooltip: {
                    enabled: false,
                    position: 'nearest',
                    external: externalTooltipHandler,
                    callbacks: {
                        label: (a) => {
                            return [a.label, a.formattedValue];
                        }
                    }
                }
            }
        }}
        plugins={[plugineEmptyDoughnut]}
    />;
}

export default CustomDonutChart;