import styled from "styled-components";

export const StepIndicatorContainer = styled.div`
    display: flex;
    align-items: center;
    column-gap: 10px;
`;

interface StepProps {
    active: boolean;
    isStepComplete: boolean;
}

const Step = styled.span<StepProps>`
    background-color: ${props => props.active ? "#2E70CC" : "transparent"};
    color: ${props => {

        if (props.active) {
            return "white";
        }

        if (props.isStepComplete) {
            return "#0070D3";
        }

        return "#657381";
    }};
    display: inline-block;
    width: 28px;
    height: 28px;
    font-size: 0.9em;
    font-weight: 700;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 50% 50%;
    // cursor: pointer;
    display: inline-flex;
    align-items: center;
    align-content: center;
    justify-content: center;
`;

interface StepsProps {
    activeStep: number;
    numSteps: number;
    handleOnClick: (selectedStepIndex: number) => void;
}

function StepIndicator({ activeStep, numSteps, handleOnClick }: StepsProps) {

    return (
        <StepIndicatorContainer className="step-indicator">
            {[...Array(numSteps)].map((x, i) =>
                <Step active={i + 1 === activeStep} isStepComplete={i < activeStep} key={`StepIndicatorStep_${i}`} onClick={() => handleOnClick(i)}>
                    {i + 1}
                </Step>
            )}
        </StepIndicatorContainer>
    );
}

export default StepIndicator;