import { IFormValues } from 'common/viewModels';
import { useFormikContext } from 'formik';
import { CustomTextbox } from 'common/components/CustomTextbox';
import CustomErrorMessage from 'common/components/CustomErrorMessage';
import { AdoptTheBlueMemberType } from 'clients/AdoptTheBlueClient';
import CustomSelect from 'common/components/CustomSelect';
import Countries from 'common/countries';

function Step1() {

    const { values } = useFormikContext<IFormValues>();
    const optionsForCountry = Countries.map(a => {
        return {
            value: a,
            label: a
        }
    });

    return (
        <>
            <div className="mb-5">

                <p className="fw-bold">Contact Information <span className="text-danger">*</span></p>

                <p>Please enter your contact details</p>

                {values.userDetails?.memberType === AdoptTheBlueMemberType.Individual &&
                    <>
                        <div className="mb-3">
                            <CustomTextbox name="userDetails.firstName" placeholder="First Name" />
                            <CustomErrorMessage name="userDetails.firstName" />
                        </div>

                        <div className="mb-3">
                            <CustomTextbox name="userDetails.lastName" placeholder="Last Name" />
                            <CustomErrorMessage name="userDetails.lastName" />
                        </div>

                        <div className="mb-3">
                            <CustomSelect name="userDetails.address.country" options={optionsForCountry} placeholder="Country" />
                            <CustomErrorMessage name="userDetails.address.country" />
                        </div>

                        <div className="mb-3">
                            <CustomTextbox name="userDetails.emailAddress" placeholder="Email Address" />
                            <CustomErrorMessage name="userDetails.emailAddress" />
                        </div>
                    </>
                }

                {values.userDetails?.memberType === AdoptTheBlueMemberType.Business &&
                    <>
                        <div className="mb-3">
                            <CustomTextbox name="userDetails.organisationName" placeholder="Diver Center or Resort Name" />
                            <CustomErrorMessage name="userDetails.organisationName" />
                        </div>

                        <div className="mb-3">
                            <CustomSelect name="userDetails.address.country" options={optionsForCountry} placeholder="Country" />
                            <CustomErrorMessage name="userDetails.address.country" />
                        </div>

                        <div className="mb-3">
                            <CustomTextbox name="userDetails.emailAddress" placeholder="Dive Business Email Address" />
                            <CustomErrorMessage name="userDetails.emailAddress" />
                        </div>
                    </>
                }

                {values.userDetails?.memberType === AdoptTheBlueMemberType.Unknown &&
                    <p>Please select a member type.</p>
                }

            </div>

            <div className="mb-5">

                <p className="fw-bold">Join our community (Optional)</p>

                <p>If you would like us to include your social media and/or website in Adopt The Blue marketing please include below.</p>

                <div className="mb-3">
                    <CustomTextbox name="userDetails.socialMediaFacebook" placeholder="Facebook" />
                    <CustomErrorMessage name="userDetails.socialMediaFacebook" />
                </div>

                <div className="mb-3">
                    <CustomTextbox name="userDetails.socialMediaInstagram" placeholder="Instagram" />
                    <CustomErrorMessage name="userDetails.socialMediaInstagram" />
                </div>

                <div className="mb-3">
                    <CustomTextbox name="userDetails.websiteUrl" placeholder="Website" />
                    <CustomErrorMessage name="userDetails.websiteUrl" />
                </div>

            </div>

        </>
    );
}

export default Step1;