import { AdoptTheBlueMemberType } from 'clients/AdoptTheBlueClient';
import Debugger from 'common/components/Debugger';
import { PadiContainer } from 'common/components/PadiContainer';
import { ToApiModel } from 'common/mapping/toApiModel';
import { IFormValues } from 'common/viewModels';
import { fetchSystemData, getSystemDataLoadStatusSelector, getRequestToAdoptTheBlueStatusSelector, requestToAdoptTheBlue } from 'features/adoptTheBlueForm/adoptTheBlueFormSlice';
import { LoadStatusEnum } from 'features/common/LoadStatus';
import { FormikHelpers, FormikProps, FormikValues } from 'formik';
import { FormikWizard } from 'formik-wizard-form';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Step0 from './components/Step0';
import Step1 from './components/Step1';
import Step2 from './components/Step2';
import Step3 from './components/Step3';
import StepEnd from './components/StepEnd';
import StepIndicator from './components/StepIndicator';
import ClipLoader from "react-spinners/ClipLoader";
import createInitialFormValues from './createInitialFormValues';

import {
    FormValidationStep0Schema,
    FormValidationStep1Schema,
    FormValidationStep2Schema,
    FormValidationStep3Schema
} from './validation';

function Index() {

    const systemDataLoadStatus = useSelector(getSystemDataLoadStatusSelector);
    const requestToAdoptTheBlueStatus = useSelector(getRequestToAdoptTheBlueStatusSelector);
    const dispatch = useDispatch();
    const [activeStepIndex, setActiveStepIndex] = useState<number>(0);

    function handleFormSubmit(values: IFormValues, setSubmitting: (isSubmitting: boolean) => void) {

        setSubmitting(true);

        const adoptTheBlueSubmissionApiModel = ToApiModel.AdoptTheBlueSubmissionApiModel(values);

        dispatch(requestToAdoptTheBlue(adoptTheBlueSubmissionApiModel));

        setSubmitting(false);
    }

    // This will run on load
    useEffect(() => {
        dispatch(fetchSystemData());
    }, [dispatch]);


    const getNextButtonName = (currentStepIndex: number, isLastStep: boolean) => {
        if (currentStepIndex === 0) {
            return "I'm ready";
        }

        if (isLastStep) {
            return "Adopt the Blue";
        }

        return 'Next';
    }

    const handleOnClick = (selectedStepIndex: number) => {
        setActiveStepIndex(selectedStepIndex);
    }

    const isFirstStep = (currentStepIndex?: number) => (currentStepIndex ?? 0) === 0;
    const isFormCompleted = () => requestToAdoptTheBlueStatus.loadStatus === LoadStatusEnum.Completed;

    const beforePrev = useCallback(
        async (
            values: FormikValues,
            formikBag: FormikProps<FormikValues>
        ): Promise<any> => {
            window.scrollTo(0, 0);
        },
        []
    );

    const beforeNext = useCallback(
        async (
            values: FormikValues,
            formikBag: FormikProps<FormikValues>
        ): Promise<any> => {
            const validationErrors = await formikBag.validateForm();

            if (Object.keys(validationErrors).length === 0) {
                window.scrollTo(0, 0);
            }
        },
        []
    );

    if (systemDataLoadStatus.loadStatus === LoadStatusEnum.Loading) {
        return <PadiContainer>
            <div className=" d-flex justify-content-center p-5">
                <ClipLoader color="#cccccc" size={96} />
            </div>
        </PadiContainer >
    }

    if (systemDataLoadStatus.loadStatus === LoadStatusEnum.Error) {
        return <PadiContainer>
            <h4 className="mb-4">Adopt The Blue</h4>

            An error occured. Please refresh the page and try again.
        </PadiContainer>
    }

    return (
        <PadiContainer>

            <FormikWizard
                enableReinitialize={true}
                initialValues={createInitialFormValues()}
                onSubmit={(values: FormikValues, { setSubmitting }: FormikHelpers<FormikValues>) => {
                    handleFormSubmit(values as IFormValues, setSubmitting);
                }}
                validateOnNext
                validateOnBlur
                activeStepIndex={activeStepIndex}
                steps={
                    [
                        {
                            component: Step0,
                            validationSchema: FormValidationStep0Schema,
                            beforePrev: beforePrev,
                            beforeNext: beforeNext
                        },
                        {
                            component: Step1,
                            validationSchema: FormValidationStep1Schema,
                            beforePrev: beforePrev,
                            beforeNext: beforeNext
                        },
                        {
                            component: Step2,
                            validationSchema: FormValidationStep2Schema,
                            beforePrev: beforePrev,
                            beforeNext: beforeNext
                        },
                        {
                            component: Step3,
                            validationSchema: FormValidationStep3Schema,
                            beforePrev: beforePrev
                        },
                    ]
                }
            >
                {({
                    renderComponent,
                    handlePrev,
                    handleNext,
                    isNextDisabled,
                    isPrevDisabled,
                    isLastStep,
                    currentStepIndex,
                    values,
                    isSubmitting
                }) => (
                    <div>

                        <h4 className="mb-4">Adopt The Blue</h4>

                        <Debugger />

                        {!isFirstStep(currentStepIndex) && !isFormCompleted() &&
                            <div className="my-3">
                                <StepIndicator numSteps={3} activeStep={(currentStepIndex ?? 0)} handleOnClick={handleOnClick} />
                            </div>
                        }

                        {isFormCompleted() &&
                            <StepEnd />
                        }

                        {!isFormCompleted() &&
                            <>
                                {renderComponent()}

                                <div className="mt-5 d-flex flex-row justify-content-between gap-2">

                                    <div>
                                        {!isFirstStep(currentStepIndex) &&
                                            <button type="button" className="btn btn-outline-primary" onClick={handlePrev} disabled={requestToAdoptTheBlueStatus.loadStatus === LoadStatusEnum.Loading || values.userDetails?.memberType === AdoptTheBlueMemberType.Unknown}>
                                                Previous
                                            </button>
                                        }
                                    </div>

                                    <button type="button" className="btn btn-primary" onClick={handleNext} disabled={requestToAdoptTheBlueStatus.loadStatus === LoadStatusEnum.Loading || values.userDetails?.memberType === AdoptTheBlueMemberType.Unknown}>
                                        {getNextButtonName(currentStepIndex ?? 0, isLastStep)}
                                    </button>

                                </div>

                                {!isFirstStep(currentStepIndex) &&
                                    <p className="mt-3"><span className="text-danger fw-bold">*</span> Indicates required fields</p>
                                }
                            </>
                        }

                    </div>
                )}
            </FormikWizard>

        </PadiContainer>
    );
}

export default Index;