import { LogoImageAdoptTheBlue } from '../LogoImages';

const styles = {
    outer: (isVisible: boolean) => {
        return {
            paddingLeft: "0px",
            display: isVisible ? "block" : "none"
        }
    },
    innerDialog: {
        maxWidth: "calc(100vw - 16px)",
        width: "730px"
    },
    content: {
        border: "none",
        maxHeight: "calc(100vh - 16px)",
        overflowY: "auto" as "auto"
    },
    body: {
        padding: "0 64px 80px 64px"
    },
    header: {
        border: "none",
    },
    title: {
        paddingBottom: "50px",
    },
    button: {
        cursor: "pointer"
    }
};

interface IAboutUsModalProps {
    isVisible: boolean;
    onClose: () => void;
}

function AboutUsModal(props: IAboutUsModalProps) {

    return (
        <>
            {props.isVisible && <div className={`modal-backdrop fade ${props.isVisible ? "show" : ""}`}></div>}

            <div className={`modal fade ${props.isVisible ? "show" : ""}`} tabIndex={-1} style={styles.outer(props.isVisible)}>
                <div className="modal-dialog modal-dialog-centered" style={styles.innerDialog}>
                    <div className="modal-content" style={styles.content}>
                        <div className="modal-header" style={styles.header}>
                            <h5>&nbsp;</h5>
                            <div className="btn-close-brand-blue" style={styles.button} data-bs-dismiss="modal" aria-label="Close" onClick={() => props.onClose()}></div>
                        </div>
                        <div className="modal-body" style={styles.body}>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col">
                                        <h4 className="modal-title fw-bold" style={styles.title}>About</h4>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4 pb-2 text-center text-md-start">
                                        <LogoImageAdoptTheBlue />
                                    </div>
                                    <div className="col-md-8">
                                        <p className="fw-bold">Adopt The Blue</p>

                                        <p>This map represents the largest network of underwater sites for conservation action. Our target is 10,000 sites by 2025.</p>

                                        <p>A flagship initiative under PADI's Marine Protected Areas strategy, this program will provide unprecedented inventory and scale to move the needle in ocean conservation. Once adopted, sites will be supported and activated to help reach the global goal to protect 30% of the ocean by 2030.</p>

                                        <p>Adopt The Blue program is managed by PADI AWARE Foundation, a non-profit charity, and powered by the passion of PADI divers around the world.</p>

                                        <p>Learn more: <a href="https://www.padi.com/aware/AdoptTheBlue" target="_blank" rel="noreferrer">AdoptTheBlue.org</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AboutUsModal;