import { getIn, useFormikContext } from 'formik';

function CustomErrorMessage({ name }: { name: string }) {

    const { errors, touched } = useFormikContext();

    const error = getIn(errors, name);
    const touch = getIn(touched, name);

    if (typeof error !== "string") {
        return null;
    }

    if (!touch) {
        return null;
    }

    return <div className="invalid-feedback d-block">{error}</div>;
}

export default CustomErrorMessage;