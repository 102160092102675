import { IconGeneralCloseSolid } from "../../Icons";

export interface FilterPillProps {
    id: string;
    text: string;
    className?: string;
    onClose: (id: string) => void;
}

export function FilterPill(props: FilterPillProps) {
    return (
        <div className={props.className}>
            <span>{props.text}</span>
            <i onClick={() => props.onClose(props.id)}><IconGeneralCloseSolid /></i>
        </div>
    );
}