import { useEffect, useState } from 'react';
import { AppConfig } from 'common/config';
import { Loader } from '@googlemaps/js-api-loader';
import { getPrimaryBrowserLanguage } from 'common/language';

interface IGoogleMapsLoaderProps {
    ready: () => JSX.Element;
    loading?: () => JSX.Element;
}

function GoogleMapsLoader({ ready, loading }: IGoogleMapsLoaderProps) {

    const [isLoaded, setIsLoaded] = useState(false);
    const apiKey = AppConfig.Settings.googleMapsApiKey;
    const language = getPrimaryBrowserLanguage() ?? "en";

    useEffect(() => {

        if (!apiKey) {
            console.log("No Google Maps API Key Set");
            setIsLoaded(true);
            return;
        }

        const loader = new Loader({
            apiKey,
            language: language
        });

        loader.load()
            .then((google) => {
                console.debug("loaded");

                setIsLoaded(true);
            })
            .catch(e => {
                console.error(e);
            });

    }, [apiKey]);

    if (!isLoaded) {
        return loading ? loading() : <>Loading...</>;
    }

    return ready();
}

export default GoogleMapsLoader;