import L, { LatLng } from "leaflet";
import 'leaflet.markercluster';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";

import {
    fetchMapMarkers,
    fetchSystemData,
    fetchMapMarkerDetails,
    getSiteListSelector,
    getSiteListStatusSelector,
    getSelectedSiteDetailsSelector,
    getAllSubmissionQuestionOptionsSelector,
    ISiteList,
    ISiteListItem,
    getShowSiteDetailsPanelSelector,
    setShowSiteDetailsPanel,
    getSubmissionQuestionByIdSelector,
    getSiteListFilterStatsSelector
} from "features/actionMap/actionMapSlice";
import { LoadStatusEnum } from "features/common/LoadStatus";
import FilterSideBar, { IFilterFormValues } from "./components/FilterSideBar/Index";
import MarkerPopUpDetails from "./components/MarkerPopUpDetails/Index";
import PositionContainer from "./components/PositionContainer";
import { CustomPopup } from "./components/CustomPopup";
import { Theme } from "./common/Theme";
import { MarkerIcon, MarkerClusterIcon } from "./common/MapIcons";
import { AdoptTheBlueMetaDataTypeIds } from "common/viewModels";
import { useMobileMediaQuery } from "common/responsiveHelpers";
import { httpsUrl } from "./common/helper";
import FilterInfoPopUp from "./components/FilterInfoPopUp";
import './Map.css';
import { getPrimaryBrowserLanguage } from "common/language";

function Index() {

    const dispatch = useDispatch();

    const position: LatLng = new LatLng(48.080154, -32.289237);
    const language = getPrimaryBrowserLanguage() ?? "en";

    const isMobileResponsive = useMobileMediaQuery();

    const submissionQuestionOptions = useSelector(getAllSubmissionQuestionOptionsSelector);
    const ecosystemQuestion = useSelector(getSubmissionQuestionByIdSelector(AdoptTheBlueMetaDataTypeIds.Ecosystem));
    const conservationIssuesQuestion = useSelector(getSubmissionQuestionByIdSelector(AdoptTheBlueMetaDataTypeIds.ConservationIssues));
    const marineProtectedOptionQuestion = useSelector(getSubmissionQuestionByIdSelector(AdoptTheBlueMetaDataTypeIds.MarineProtected));

    const siteList = useSelector(getSiteListSelector);
    const siteListStatus = useSelector(getSiteListStatusSelector);
    const siteListFilterStats = useSelector(getSiteListFilterStatsSelector);

    const selectedSiteDetails = useSelector(getSelectedSiteDetailsSelector);

    const showSiteDetailsPanelSelector = useSelector(getShowSiteDetailsPanelSelector);

    // State management

    const [map, setMap] = useState<L.Map>();
    const [mapMarkerClusterGroupLayer, setMapMarkerClusterGroupLayer] = useState<L.MarkerClusterGroup>();
    const [isFilterBarCollapsed, setIsFilterBarCollapsed] = useState<boolean>(isMobileResponsive);
    const [currentlySelectedMarker, setCurrentlySelectedMarker] = useState<L.Marker>();

    useEffect(() => {

        var mapInstance = createMap();

        setMap(mapInstance);

    }, [])

    // This will run on load
    useEffect(() => {
        dispatch(fetchSystemData());
    }, [dispatch]);

    // This will listen for changes to siteList
    useEffect(() => {

        if (!siteList || siteListStatus.loadStatus !== LoadStatusEnum.Completed || !map || !siteList?.sites) {
            return;
        }

        addMarkers(map, siteList);

    }, [map, siteList, siteListStatus]);

    const handleMarkerOnClick = (marker: L.Marker, item: ISiteListItem) => {

        setCurrentlySelectedMarker(marker);

        // marker.setIcon(MarkerIconSelected);

        dispatch(fetchMapMarkerDetails(item.uId));
        dispatch(setShowSiteDetailsPanel(true));
    }

    const handlePopUpClose = (marker: L.Marker, item: ISiteListItem) => {
        // marker.setIcon(MarkerIcon);

        dispatch(setShowSiteDetailsPanel(false));
    }

    function createMap(): L.Map {
        var mapInstance = L.map("mapView", {
            preferCanvas: true,
            maxZoom: 18,
            minZoom: 1
        })
            .setView(position, isMobileResponsive ? 2 : 3);

        const googleStreets = L.tileLayer(`https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}&hl=${language}`, {
            attribution: 'Copyright Google Maps',
            subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
        });

        mapInstance.addLayer(googleStreets);

        return mapInstance;
    }

    function addMarkers(map: L.Map, siteList: ISiteList) {

        if (!map) throw new Error("Map not initialised");
        if (!siteList?.sites) throw new Error("siteList was empty");

        if (mapMarkerClusterGroupLayer) {
            map.removeLayer(mapMarkerClusterGroupLayer);
        }

        var markerClusterGroupLayer = new L.MarkerClusterGroup({
            iconCreateFunction: function (cluster) {
                return MarkerClusterIcon(cluster.getChildCount());
            }
        });

        map.addLayer(markerClusterGroupLayer);

        setMapMarkerClusterGroupLayer(markerClusterGroupLayer);

        siteList.sites.forEach(item => {

            var popup = new CustomPopup(item.name, item.lat, item.lon);

            var marker = L.marker(
                [item.lat, item.lon],
                {
                    icon: MarkerIcon
                }
            );

            marker.addEventListener("click", (e: L.LeafletEvent) => handleMarkerOnClick(marker, item));
            marker.addEventListener("popupclose", () => handlePopUpClose(marker, item));

            marker.bindPopup(popup, {
                maxWidth: 500
            });

            markerClusterGroupLayer.addLayer(marker);
        });
    }

    function handleFilterFormSubmit(values?: IFilterFormValues) {

        let chosenSubmissionQuestionOptions: number[] = [];

        values?.chosenEcosystemOptions?.forEach(element => {
            chosenSubmissionQuestionOptions.push(parseInt(element.value));
        });

        values?.chosenConservationIssueOptions?.forEach(element => {
            chosenSubmissionQuestionOptions.push(parseInt(element.value));
        });

        values?.chosenMarineProtectionStatusOptions?.forEach(element => {
            chosenSubmissionQuestionOptions.push(parseInt(element.value));
        });

        dispatch(fetchMapMarkers({
            chosenSubmissionQuestionOptions: chosenSubmissionQuestionOptions
        }));
    }

    function handleMarkerPopUpDetailsOnClose() {
        currentlySelectedMarker?.closePopup();
    }

    function getSubmissionQuestionOptionDisplay(arr: number[]): string[] {

        if (!submissionQuestionOptions?.length) {
            return [];
        }

        const ret = arr.map(a => submissionQuestionOptions.find(p => p.id === a)?.name ?? "");

        return ret;
    }

    return (
        <ThemeProvider theme={Theme}>

            {siteListFilterStats &&
                <PositionContainer className="filterInfoPopUp" position="fixed" location={"bottomleft"} width={312} bottom={10} left={10}>
                    <FilterInfoPopUp
                        isFiltered={siteListFilterStats.isFiltered}
                        numResultsTotal={siteListFilterStats.numResultsTotal}
                        numResultsFiltered={siteListFilterStats.numResultsFiltered}
                        areaTotal={siteListFilterStats.areaTotal}
                        areaFiltered={siteListFilterStats.areaFiltered}
                    />
                </PositionContainer>
            }

            <div className="position-relative overflow-hidden">
                <div id="mapView" className="map" />

                <FilterSideBar
                    isCollapsed={isFilterBarCollapsed}
                    onToggleVisibility={() => { setIsFilterBarCollapsed(!isFilterBarCollapsed); }}
                    onFormSubmit={handleFilterFormSubmit}
                    allSubmissionQuestionOptions={submissionQuestionOptions}
                    ecosystemQuestion={ecosystemQuestion}
                    conservationIssuesQuestion={conservationIssuesQuestion}
                    marineProtectedOptionQuestion={marineProtectedOptionQuestion}
                />

                {showSiteDetailsPanelSelector && selectedSiteDetails &&
                    <PositionContainer position="absolute" location={isMobileResponsive ? "bottomleft" : "topleft"} bottom={10} left={10} top={isMobileResponsive ? 10 : 115} zIndex={Theme.zOrder.markerPopupDetails} /*bottom={0}*/>
                        <MarkerPopUpDetails
                            onClose={handleMarkerPopUpDetailsOnClose}
                            title={selectedSiteDetails.siteName}
                            lat={selectedSiteDetails.lat}
                            lng={selectedSiteDetails.lon}

                            name={selectedSiteDetails.individualOrOrganisationName}
                            memberType={selectedSiteDetails.memberType}
                            dateAdded={selectedSiteDetails.dateAdded}
                            chosenEcosystems={getSubmissionQuestionOptionDisplay(selectedSiteDetails.ecosystemChosenOptions)}
                            chosenConservationIssues={getSubmissionQuestionOptionDisplay(selectedSiteDetails.conservationIssueChosenOptions)}
                            chosenMarineProtectionStatus={selectedSiteDetails.marineProtectedAreaChosenOption}

                            contactEmail={selectedSiteDetails.emailAddress}
                            contactWebsite={httpsUrl(selectedSiteDetails.websiteUrl)}
                            contactInstagram={selectedSiteDetails.socialMediaInstagram}
                            contactFacebook={selectedSiteDetails.socialMediaFacebook}
                        />
                    </PositionContainer>
                }
            </div>

        </ThemeProvider>
    );
}

export default Index;