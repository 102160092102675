export interface IApiClientConfig {
    hasActiveSession(): boolean;
    logOut(): void;

    getAuthToken(): string;
    setAuthToken(accessToken: string): void;

    getRefreshToken(): string;
    setRefreshToken(refreshToken: string): void;
}

export class ApiClientConfig implements IApiClientConfig {

    hasActiveSession() {
        return !!sessionStorage.authToken;
    }

    logOut() {
        sessionStorage.removeItem("authToken");
        sessionStorage.removeItem("refreshToken");
    }

    getAuthToken = (): string => sessionStorage.authToken;
    setAuthToken(accessToken: string): void {
        sessionStorage.setItem("authToken", accessToken);
    }

    getRefreshToken = (): string => sessionStorage.refreshToken;
    setRefreshToken(refreshToken: string): void {
        sessionStorage.setItem("refreshToken", refreshToken);
    }
}

export class ClientBase {
    private readonly apiClientConfig: IApiClientConfig;

    protected constructor(apiClientConfig: IApiClientConfig) {
        this.apiClientConfig = apiClientConfig;
    }
}