import { useSelector } from "react-redux";
import styled from "styled-components";
import { Formik, Form } from "formik";

import { Theme } from "../../common/Theme";
import CustomDonutChart from "../CustomDonutChart";
import { IconGeneralClose } from "../Icons";
import PositionContainer from "../PositionContainer";
import CustomMultiSelect from "common/components/CustomMultiSelect";
import { ISubmissionQuestionBulkApiModel, ISubmissionQuestionOptionBulkApiModel } from "clients/AdoptTheBlueClient";
import { FilterPill } from "./components/FilterPill";
import { AdoptTheBlueMetaDataTypeIds } from 'common/viewModels';
import {
    getSiteListStatsSelector
} from "features/actionMap/actionMapSlice";
import FormChangeListener from "./components/FormChangeListener";
import FilterToggleButton from "./components/FilterToggleButton";
import BottomControls from "./components/BottomControls";
import { createChosenOptions, createChosenOptionsMpa, getSiteListStatusById, IChosenOption, removeChosenOptionById } from "pages/Map/common/helper";
import { useMobileMediaQuery } from "common/responsiveHelpers";
import { LogoImageAdoptTheBlue } from "../LogoImages";

const FilterPillStyled = styled(FilterPill)`
    color: #FFFFFF;

    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 5px 5px 10px;
    gap: 5px;

    background: ${props => props.theme.colors.blueBrand};
    border-radius: 31px;

    i {
        width: 15px;
        height: 15px;
        cursor: pointer;
    }
`;

const LogoImageAdoptTheBlueContainer = styled.div`
    padding-right: 20px;
    height: 54px !important;

    span {
        width: 54px !important;
        height: 54px !important;
    }
`;

interface IFilterSideBarProps {
    isCollapsed: boolean;
    onToggleVisibility: () => void;
    onFormSubmit: (values: IFilterFormValues) => void;
    allSubmissionQuestionOptions?: ISubmissionQuestionOptionBulkApiModel[];
    ecosystemQuestion?: ISubmissionQuestionBulkApiModel;
    conservationIssuesQuestion?: ISubmissionQuestionBulkApiModel;
    marineProtectedOptionQuestion?: ISubmissionQuestionBulkApiModel;
}

export interface IFilterFormValues {
    chosenEcosystemOptions?: IChosenOption[];
    chosenConservationIssueOptions?: IChosenOption[];
    chosenMarineProtectionStatusOptions?: IChosenOption[];
}

function FilterSideBar(props: IFilterSideBarProps) {

    // Selectors, State, etc

    const siteListStats = useSelector(getSiteListStatsSelector);

    const ecosystemOptions = createChosenOptions(props.ecosystemQuestion?.options);
    const conservationIssueOptions = createChosenOptions(props.conservationIssuesQuestion?.options);
    const marineProtectedOptions = createChosenOptionsMpa();

    const isMobileResponsive = useMobileMediaQuery();

    // Helpers

    function createInitialValues(): IFilterFormValues {
        return {
            chosenEcosystemOptions: undefined,
            chosenConservationIssueOptions: undefined,
            chosenMarineProtectionStatusOptions: undefined
        };
    }

    // Handlers

    const handleFilterPillOnClose = (id: string, values: IFilterFormValues, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
        removeChosenOptionById(id, values, setFieldValue);
    };

    const handleResetFormCustom = (setFieldValue: any) => {
        setFieldValue("chosenEcosystemOptions", []);
        setFieldValue("chosenConservationIssueOptions", []);
        setFieldValue("chosenMarineProtectionStatusOptions", []);
    }

    return (
        <>
            <PositionContainer
                className="filterToggleButton"
                position="absolute" location={"topright"}
                top={10}
                right={props.isCollapsed ? 10 : -320}
                width={320}
                zIndex={Theme.zOrder.filterToggleButton}>
                <FilterToggleButton onToggleVisibility={() => props.onToggleVisibility()} isCollapsed={props.isCollapsed} />
            </PositionContainer>

            <PositionContainer
                className="mapFilterSidebar"
                position="absolute" location={"topright"}
                top={0}
                right={!props.isCollapsed ? 0 : (isMobileResponsive ? -768 : -320)}
                width={isMobileResponsive ? "100vw" : 320}
                zIndex={Theme.zOrder.filterSideBar}
            >
                <Formik
                    initialValues={createInitialValues}
                    onSubmit={(values: IFilterFormValues) => props.onFormSubmit(values)}
                >
                    {({
                        values,
                        setFieldValue,
                    }) => (
                        <Form>
                            <FormChangeListener onFormChange={(values) => props.onFormSubmit(values)} />

                            <div className={`mapFilterSidebarInner ${props.isCollapsed ? "" : "open"} p-3`} style={{
                                height: "calc(100vh - 80px)",
                                width: isMobileResponsive ? "100vw" : "320px",
                                background: Theme.colors.white,
                                zIndex: "100"
                            }}>
                                <div className="d-flex justify-content-between align-items-center">
                                    <div>Data Options</div>
                                    <span className="d-flex align-items-center cursor-pointer" onClick={() => props.onToggleVisibility()}>
                                        <IconGeneralClose />
                                    </span>
                                </div>

                                <div className="pt-4 pb-5 pb-md-0">
                                    <div className="pb-3">

                                        <div className="d-flex flex-row align-items-center">
                                            <LogoImageAdoptTheBlueContainer>
                                                <LogoImageAdoptTheBlue />
                                            </LogoImageAdoptTheBlueContainer>

                                            <div className="d-flex flex-column">
                                                <span className="fw-bold">Adopt The Blue™ Data</span>
                                                <div className="fs-7">Sponsored by Blancpain</div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="border-top w-100 py-4">
                                        <div className="w-100" style={{ zIndex: Theme.zOrder.dropdown }}>
                                            <CustomMultiSelect
                                                name="chosenEcosystemOptions"
                                                valueContainerLabel="Ecosystems"
                                                options={ecosystemOptions}
                                                values={values.chosenEcosystemOptions}
                                            />
                                        </div>

                                        <div className="d-flex flex-wrap gap-1 py-2">
                                            {values.chosenEcosystemOptions?.map(a =>
                                                <FilterPillStyled key={`filter-pill-${a.value}`} id={a.value} text={a.label} onClose={(id) => handleFilterPillOnClose(id, values, setFieldValue)} />
                                            )}
                                        </div>

                                        <div className="d-flex flex-column justify-content-center align-items-center pt-3">
                                            <PositionContainer location="none" width={135} zIndex={Theme.zOrder.chart}>
                                                <CustomDonutChart
                                                    backgroundColors={Theme.shades.blue}
                                                    labels={getSiteListStatusById(siteListStats, AdoptTheBlueMetaDataTypeIds.Ecosystem)?.labels ?? []}
                                                    data={getSiteListStatusById(siteListStats, AdoptTheBlueMetaDataTypeIds.Ecosystem)?.data ?? []}
                                                />
                                            </PositionContainer>
                                        </div>

                                    </div>

                                    <div className="border-top w-100 py-4">

                                        <div className="w-100" style={{ zIndex: Theme.zOrder.dropdown }}>
                                            <CustomMultiSelect
                                                name="chosenConservationIssueOptions"
                                                valueContainerLabel="Conservation Issues"
                                                options={conservationIssueOptions}
                                                values={values.chosenConservationIssueOptions}
                                            />
                                        </div>

                                        <div className="d-flex flex-wrap gap-1 py-2">
                                            {values.chosenConservationIssueOptions?.map(a =>
                                                <FilterPillStyled key={`filter-pill-${a.value}`} id={a.value} text={a.label} onClose={(id) => handleFilterPillOnClose(id, values, setFieldValue)} />
                                            )}
                                        </div>

                                        <div className="d-flex flex-column justify-content-center align-items-center pt-3" style={{ zIndex: Theme.zOrder.chart }}>
                                            <PositionContainer location="none" width={135}>
                                                <CustomDonutChart
                                                    backgroundColors={Theme.shades.blue}
                                                    labels={getSiteListStatusById(siteListStats, AdoptTheBlueMetaDataTypeIds.ConservationIssues)?.labels ?? []}
                                                    data={getSiteListStatusById(siteListStats, AdoptTheBlueMetaDataTypeIds.ConservationIssues)?.data ?? []}
                                                />
                                            </PositionContainer>
                                        </div>
                                    </div>

                                    <div className="border-top w-100 py-4">

                                        <div className="w-100" style={{ zIndex: Theme.zOrder.dropdown }}>
                                            <CustomMultiSelect
                                                name="chosenMarineProtectionStatusOptions"
                                                valueContainerLabel="MPA Status"
                                                options={marineProtectedOptions}
                                                values={values.chosenMarineProtectionStatusOptions}
                                            />
                                        </div>

                                        <div className="d-flex flex-wrap gap-1 py-2">
                                            {values.chosenMarineProtectionStatusOptions?.map(a =>
                                                <FilterPillStyled key={`filter-pill-${a.value}`} id={a.value} text={a.label} onClose={(id) => handleFilterPillOnClose(id, values, setFieldValue)} />
                                            )}
                                        </div>

                                        <div className="d-flex flex-column justify-content-center align-items-center pt-3" style={{ zIndex: Theme.zOrder.chart }}>
                                            <PositionContainer location="none" width={135}>
                                                <CustomDonutChart
                                                    backgroundColors={Theme.shades.blue}
                                                    labels={getSiteListStatusById(siteListStats, AdoptTheBlueMetaDataTypeIds.MarineProtected)?.labels ?? []}
                                                    data={getSiteListStatusById(siteListStats, AdoptTheBlueMetaDataTypeIds.MarineProtected)?.data ?? []}
                                                />
                                            </PositionContainer>
                                        </div>
                                    </div>

                                </div>

                                <BottomControls onResetForm={handleResetFormCustom} onToggleVisibility={props.onToggleVisibility} isCollapsed={props.isCollapsed} />

                            </div>
                        </Form>
                    )}
                </Formik>

            </PositionContainer>
        </>
    );
}

export default FilterSideBar;