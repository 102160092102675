import { useState } from 'react';
import cc from "classcat";

import PartnersModal from 'pages/Map/components/Modals/PartnersModal';
import AboutUsModal from 'pages/Map/components/Modals/AboutUsModal';
import { ReactComponent as Logo } from './images/logo_padi_aware_foundation_hor.svg';
import './Header.css';
import { IconGeneralClose, IconGeneralFilterCollapsed } from 'pages/Map/components/Icons';

function Header() {

    const [isSponsorsModalOpen, setIsSponsorsModalOpen] = useState<boolean>(false);
    const [isAboutUsModalOpen, setIsAboutUsModalOpen] = useState<boolean>(false);
    const [isMenuCollapsed, setIsMenuCollapsed] = useState<boolean>(true);

    return (
        <>
            <PartnersModal isVisible={isSponsorsModalOpen} onClose={() => setIsSponsorsModalOpen(false)} />
            <AboutUsModal isVisible={isAboutUsModalOpen} onClose={() => setIsAboutUsModalOpen(false)} />

            <nav className="navbar navbar-expand-md">
                <div className="container-fluid">
                    <a className="navbar-brand" href="https://www.padi.com/aware">
                        <div style={{ "padding": "20px" }}>
                            <div aria-label="PADI" className="logo" style={{ "width": "176px", "height": "40px" }}>
                                <Logo />
                            </div>
                        </div>
                    </a>
                    <button onClick={() => setIsMenuCollapsed(!isMenuCollapsed)} className={cc(["navbar-toggler", { "collapsed": isMenuCollapsed }])} type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded={!isMenuCollapsed} aria-label="Toggle navigation">
                        {/* <span className="navbar-toggler-icon"></span> */}
                        {isMenuCollapsed ? <IconGeneralFilterCollapsed /> : <IconGeneralClose />}
                    </button>
                    <div className={cc(["navbar-collapse collapse", { "show": !isMenuCollapsed }])} id="navbarCollapse">
                        <ul className="navbar-nav text-center">
                            <li className="nav-item">
                                <a href="#" className="nav-link px-2 link-dark text-brand-blue fw-bold text-decoration-none pe-auto" onClick={() => setIsAboutUsModalOpen(true)}>About</a>
                            </li>
                            <li className="nav-item">
                                <a href="#" className="nav-link px-2 link-secondary text-brand-blue fw-bold text-decoration-none pe-auto" onClick={() => setIsSponsorsModalOpen(true)}>Partners</a>
                            </li>
                            <li className="nav-item">
                                <div className="px-2">
                                    <img src="/images/logos/blancpain.svg" className="blancpain-logo" />
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>

            {/* <div className="container-fluid">
                <div className="d-flex flex-row">
                    <div style={{ "padding": "20px" }}>
                        <div aria-label="PADI" className="logo" style={{ "width": "176px", "height": "40px" }}>
                            <a href="https://www.padi.com/aware">
                                <Logo />
                            </a>
                        </div>
                    </div>

                    <div className="align-self-center">
                        <ul className="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0">
                            <li className="px-1">
                                <a href="#" className="nav-link px-2 link-dark text-brand-blue fw-bold text-decoration-none pe-auto" onClick={() => setIsAboutUsModalOpen(true)}>About</a>
                            </li>
                            <li className="px-1">
                                <a href="#" className="nav-link px-2 link-secondary text-brand-blue fw-bold text-decoration-none pe-auto" onClick={() => setIsSponsorsModalOpen(true)}>Partners</a>
                            </li>
                        </ul>
                    </div>

                </div>
            </div> */}
        </>
    );
}

export default Header;