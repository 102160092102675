import { LogoImageBlancpainBOC } from '../LogoImages';

const styles = {
    outer: (isVisible: boolean) => {
        return {
            paddingLeft: "0px",
            display: isVisible ? "block" : "none"
        }
    },
    innerDialog: {
        maxWidth: "calc(100vw - 16px)",
        width: "730px"
    },
    content: {
        border: "none",
        maxHeight: "calc(100vh - 16px)",
        overflowY: "auto" as "auto"
    },
    body: {
        padding: "0 64px 80px 64px"
    },
    header: {
        border: "none",
    },
    title: {
        paddingBottom: "50px",
    },
    button: {
        cursor: "pointer"
    }
};

interface IPartnersModalProps {
    isVisible: boolean;
    onClose: () => void;
}

function PartnersModal(props: IPartnersModalProps) {

    return (
        <>
            {props.isVisible && <div className={`modal-backdrop fade ${props.isVisible ? "show" : ""}`}></div>}

            <div className={`modal fade ${props.isVisible ? "show" : ""}`} tabIndex={-1} style={styles.outer(props.isVisible)}>
                <div className="modal-dialog modal-dialog-centered" style={styles.innerDialog}>
                    <div className="modal-content" style={styles.content}>
                        <div className="modal-header" style={styles.header}>
                            <h5>&nbsp;</h5>
                            <div className="btn-close-brand-blue" style={styles.button} data-bs-dismiss="modal" aria-label="Close" onClick={() => props.onClose()}></div>
                        </div>
                        <div className="modal-body" style={styles.body}>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col">
                                        <h4 className="modal-title fw-bold" style={styles.title}>Partners</h4>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4 pb-2 text-center text-md-start">
                                        <img src="/images/logos/blancpain-boc.svg" alt="Blancpain Ocean Commitment Logo" style={{"maxWidth": "250px"}} />
                                    </div>
                                    <div className="col-md-8">
                                        <p className="fw-bold">Blancpain</p>
                                        <p>Blancpain, the world's premier manufacturer of prestigious watches, enjoys a long tradition with the ocean dating back to 1953s launch of the Fifty Fathoms, the world's first modern diving watch. </p>
                                        <p>Blancpain has devoted itself to the cause of ocean exploration, preservation and protection, and is proud to be the founding partner of PADI AWARE's Adopt The Blue Program and PADI's Marine Protected Area Blueprint goal.</p>
                                    </div>
                                </div>
                                <div className="row pt-5">
                                    <div className="col">
                                        <p className="fw-bold">Partners &amp; Data Sharing</p>

                                        <p>
                                            Adopt The Blue provides a vital delivery mechanism for the broader ocean conservation
                                            movement. <a href="https://www.padi.com/awareprivacypolicy" target="_blank" rel="noreferrer">With the permission</a> and
                                            participation of PADI members and supporters worldwide, relevant ocean-related data collected at sites will be used
                                            to assist local governments, partners and non-profits to advance marine protection. If you would like to deploy a project
                                            or to set up a data sharing agreement you can contact us at <a href="mailto:information@padiaware.org">information@padiaware.org</a>.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PartnersModal;