export enum LoadStatusEnum {
    Idle = "Idle",
    Loading = "Loading",
    Error = "Error",
    Completed = "Completed"
}

export interface ILoadStatus {
    loadStatus?: LoadStatusEnum;
    errorMessage?: string;
}