import { createSiteNameFullPreview } from 'common/helpers';
import { IFormValues } from 'common/viewModels';
import { useFormikContext } from 'formik';

function StepEnd() {

    const { values } = useFormikContext<IFormValues>();

    const fullSiteName = createSiteNameFullPreview(values);

    return (
        <>
            <p className="fw-bold">You've Adopted The Blue!</p>

            <p>Your site name is {fullSiteName}. We have sent a confimation email to {values?.userDetails?.emailAddress}.</p>
        </>
    );
}

export default StepEnd;