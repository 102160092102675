export interface IAppConfig {
    configUrl: string;
    pathBase: string;
    apiBaseUrl: string;
    reCaptchaApiKey: string;
    googleMapsApiKey: string;
    isDebugModeEnabled: boolean;    
}

function throwConfigError(paramName: string): never {
    throw new Error(`Missing required config parameter: ${paramName}`);
}

export class AppConfig {

    public static Settings: IAppConfig = {
        configUrl: process.env.REACT_APP_CONFIG_URL ?? throwConfigError("REACT_APP_CONFIG_URL"),
        pathBase: '',
        apiBaseUrl: '',
        reCaptchaApiKey: '',
        googleMapsApiKey: '',
        isDebugModeEnabled: false,
    }

    async load() {

        const t = (new Date()).getTime();
        const jsonFile = `${AppConfig.Settings.configUrl}?t=${t}`;

        var response = await fetch(jsonFile);

        var json = await response.json();

        AppConfig.Settings = json as IAppConfig;
    }
}

export const Config = new AppConfig();