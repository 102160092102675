import { useState } from 'react';
import { PadiContainer } from 'common/components/PadiContainer';
import FilterInfoPopUp from "./components/FilterInfoPopUp";
import { ThemeProvider } from "styled-components";
import { Theme } from './common/Theme';
import MarkerPopUpDetails from './components/MarkerPopUpDetails/Index';
import { ActionMapMpaStatus, AdoptTheBlueMemberType } from 'clients/AdoptTheBlueClient';
import {
    IconMapMarker,
    IconMapMarkerSelected,
    IconMapMarkerCluster,

    IconContactEmail,
    IconContactFacebook,
    IconContactInstagram,
    IconContactWebsite,

    IconGeneralClose,
    IconGeneralConservationIssues,
    IconGeneralDate,
    IconGeneralMemberIndividual,
    IconGeneralMemberBusiness,
    IconGeneralEcosystem,
    IconGeneralMarker,
    IconGeneralMpaUnprotected,
    IconGeneralMpaPending,
    IconGeneralMpaUnknown,
    IconGeneralMpaProtected
} from './components/Icons';
import PositionContainer from './components/PositionContainer';
import CustomDonutChart from './components/CustomDonutChart';

function Debugger() {

    const [filterInfoPopUpChecked, setFilterInfoPopUpChecked] = useState(false);

    return (
        <PadiContainer>
            <ThemeProvider theme={Theme}>

                <h1>Map Debugger</h1>

                <div className="mt-5">
                    <h3>Icons</h3>

                    <div>
                        <IconMapMarker />
                        <IconMapMarkerSelected />
                        <IconMapMarkerCluster />
                    </div>

                    <div className='bg-secondary d-inline-block'>
                        <IconContactEmail />
                        <IconContactFacebook />
                        <IconContactInstagram />
                        <IconContactWebsite />
                    </div>

                    <div>
                        <IconGeneralClose />
                        <IconGeneralConservationIssues />
                        <IconGeneralDate />
                        <IconGeneralMemberIndividual />,
                        <IconGeneralMemberBusiness />,
                        <IconGeneralEcosystem />
                        <IconGeneralMarker />
                    </div>
                    <div>
                        <IconGeneralMpaUnprotected />
                        <IconGeneralMpaPending />
                        <IconGeneralMpaUnknown />
                        <IconGeneralMpaProtected />
                    </div>
                </div>

                <div className="mt-5">

                    <h3>{"<MarkerPopUpDetails /> for Individual"}</h3>

                    <br />

                    <PositionContainer location="none" width={360}>
                        <MarkerPopUpDetails
                            onClose={() => alert("closed")}

                            title={"BHP JETTY S12345"}
                            lat={-32.209642}
                            lng={115.767188}

                            name={"Terry Butler"}
                            memberType={AdoptTheBlueMemberType.Individual}
                            dateAdded={"28 April 2013"}

                            chosenEcosystems={["Coral Reef", "Fresh Water"]}
                            chosenConservationIssues={["Coral Bleaching", "Habitat Loss"]}
                            chosenMarineProtectionStatus={ActionMapMpaStatus.Pending}

                            contactEmail={"terry.butler@rantmedia.com"}
                            contactWebsite={"https://www.rantmedia.com/"}
                            contactInstagram={"RantmediaUK"}
                            contactFacebook={"RantmediaUK"}
                        />
                    </PositionContainer>

                </div>

                <div className="mt-5">

                    <h3>{"<MarkerPopUpDetails /> for Business"}</h3>

                    <br />

                    <PositionContainer location="none" width={360}>
                        <MarkerPopUpDetails
                            onClose={() => alert("closed")}

                            title={"BHP JETTY S12345"}
                            lat={-32.209642}
                            lng={115.767188}

                            name={"Terry Ltd"}
                            memberType={AdoptTheBlueMemberType.Business}
                            dateAdded={"28 April 2013"}

                            chosenEcosystems={["Coral Reef", "Fresh Water"]}
                            chosenConservationIssues={["Coral Bleaching", "Habitat Loss"]}
                            chosenMarineProtectionStatus={ActionMapMpaStatus.Protected}

                            contactEmail={"terry.butler@rantmedia.com"}
                            contactWebsite={"https://www.rantmedia.com/"}
                            contactInstagram={"RantmediaUK"}
                            contactFacebook={"RantmediaUK"}
                        />
                    </PositionContainer>

                </div>

                <div className="mt-5">

                    <h3>{"<FilterInfoPopUp />"}</h3>

                    <br />

                    <strong>Filtered:</strong>

                    <PositionContainer location="none" width={312}>
                        <FilterInfoPopUp
                            isFiltered={filterInfoPopUpChecked}
                            numResultsTotal={500}
                            numResultsFiltered={filterInfoPopUpChecked ? 250 : 500}
                            areaTotal={3600}
                            areaFiltered={filterInfoPopUpChecked ? 1800 : 3600}
                        />
                    </PositionContainer>

                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" id="filterInfoPopUpChecked"
                            checked={filterInfoPopUpChecked}
                            onChange={(e) => setFilterInfoPopUpChecked(e.target.checked)} />
                        <label className="form-check-label" htmlFor="filterInfoPopUpChecked">
                            Is Filtered?
                        </label>
                    </div>

                    <br />

                </div>

                <div className="mt-5">

                    <h3>Donut Charts</h3>

                    <PositionContainer location="none" width={135}>
                        <CustomDonutChart
                            backgroundColors={Theme.shades.green}
                            data={Array.from({ length: 8 }, () => Math.floor(Math.random() * 200))}
                        />
                    </PositionContainer>

                    <PositionContainer location="none" width={135}>
                        <CustomDonutChart
                            backgroundColors={Theme.shades.red}
                            data={Array.from({ length: 8 }, () => Math.floor(Math.random() * 200))}
                        />
                    </PositionContainer>

                    <PositionContainer location="none" width={135}>
                        <CustomDonutChart
                            backgroundColors={Theme.shades.orange}
                            data={Array.from({ length: 8 }, () => Math.floor(Math.random() * 200))}
                        />
                    </PositionContainer>

                    <PositionContainer location="none" width={135}>
                        <CustomDonutChart
                            backgroundColors={Theme.shades.orange}
                            data={[]}
                        />
                    </PositionContainer>

                </div>

            </ThemeProvider>
        </PadiContainer >
    );
}

export default Debugger;