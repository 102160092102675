import { useEffect } from 'react';
import { useFormikContext } from 'formik'
import { IFilterFormValues } from '../Index';
import { useDebounce } from 'use-debounce';

interface IFormChangeListenerProps {
    onFormChange: (values: IFilterFormValues) => void;
}

function FormChangeListener(props: IFormChangeListenerProps) {

    const { onFormChange } = props;
    const { values } = useFormikContext<IFilterFormValues>();
    const [debouncedValues] = useDebounce(values, 500);

    useEffect(() => {
        onFormChange(debouncedValues);
    }, [debouncedValues])

    return null;
}

export default FormChangeListener;