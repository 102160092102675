import { AdoptTheBlueClient } from './AdoptTheBlueClient';
import axios, { AxiosInstance } from 'axios';
import { ApiClientConfig } from "./ClientBase";
import { AppConfig } from 'common/config';

export class AdoptTheBlueClientInstance {

    private constructor(
        axiosApiInstance: AxiosInstance,
        adoptTheBlueClient: AdoptTheBlueClient,
        apiClientConfig: ApiClientConfig
    ) {
        this.AxiosApiInstance = axiosApiInstance;
        this.AdoptTheBlueClient = adoptTheBlueClient;
        this.ApiClientConfig = apiClientConfig;

        // this.AxiosApiInstance = this.setupInterceptorsTo(this.AxiosApiInstance);
    }

    public AxiosApiInstance: AxiosInstance;
    public AdoptTheBlueClient: AdoptTheBlueClient;
    public ApiClientConfig;

    public static create(): AdoptTheBlueClientInstance {

        var axiosApiInstance = axios.create();
        var apiClientConfig = new ApiClientConfig();

        var client = new AdoptTheBlueClient(
            apiClientConfig,
            AppConfig.Settings.apiBaseUrl,
            axiosApiInstance
        );

        let ret = new AdoptTheBlueClientInstance(axiosApiInstance, client, apiClientConfig);

        return ret;
    }
}