import { AdoptTheBlueMemberType, AdoptTheBlueMetric } from "clients/AdoptTheBlueClient";
import { IFormValues, IGeoLocationApiModel } from "common/viewModels";
import * as Yup from 'yup';

const geoLocationValidation: Yup.SchemaOf<IGeoLocationApiModel> = Yup.object({
    latitude: Yup.number(),
    longitude: Yup.number()
})
    .test("geoLocationValidationObjectTests", "Please select a location", value => {

        if (!value?.latitude || !value?.longitude) {
            return false;
        }

        return true;
    })

export const FormValidationStep0Schema = Yup.object({
    userDetails: Yup.object({
        memberType: Yup
            .mixed()
            .oneOf([AdoptTheBlueMemberType.Individual, AdoptTheBlueMemberType.Business, AdoptTheBlueMemberType.Unknown])
            .defined("Please choose an option"),
        padiMembershipNumber: Yup.string()
            .when("memberType", {
                is: AdoptTheBlueMemberType.Individual,
                then: Yup.string()
                    .matches(/^[0-9]+$/, 'A PADI Membership Number can only be numbers')
                    .required("A PADI Membership Number is required")
            }),
        padiStoreNumber: Yup.string()
            .when("memberType", {
                is: AdoptTheBlueMemberType.Business,
                then: Yup.string()
                    .matches(/^[0-9]+$/, 'A PADI Store Number can only be numbers')
                    .required("A PADI Store Number is required")
            }),
    })
});

export const FormValidationStep1Schema = Yup.object({
    userDetails: Yup.object({
        firstName: Yup.string()
            .when("memberType", {
                is: AdoptTheBlueMemberType.Individual,
                then: Yup.string().required("First Name is required")
            }),
        lastName: Yup.string()
            .when("memberType", {
                is: AdoptTheBlueMemberType.Individual,
                then: Yup.string().required("Last Name is required")
            }),
        emailAddress: Yup.string()
            .required("Email is required")
            .email("A valid Email is required"),
        organisationName: Yup.string()
            .when("memberType", {
                is: AdoptTheBlueMemberType.Business,
                then: Yup.string().required("Organisation Name is required")
            }),
        websiteUrl: Yup.string()
            .matches(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:\/?#[\]@!\$&'\(\)\*\+,;=.]+$/, "A valid Website URL is required"),
        address: Yup.object({
            country: Yup.string()
                .required("Country is required")
        })
    })
});

export const FormValidationStep2Schema = Yup.object({
    siteDetails: Yup.object({
        diveLocation: Yup.string()
            .required("Site Name is required"),
        estimatedDiveSiteArea: Yup.number()
            .typeError("Estimated Dive Site Area must be a number")
            .required("Estimated Dive Site Area is required"),
        estimatedDiveSiteAreaMetric: Yup.mixed().oneOf(Object.values(AdoptTheBlueMetric)).defined("Please select feet or meters"),
        location: geoLocationValidation,
    }),
});

export const FormValidationStep3Schema = Yup.object({
    siteDetails: Yup.object({
        ecosystemChosenOptions: Yup.array()
            .min(1, "At least one ecosystem option is required")
            .required("At least one ecosystem option is required"),
        ecosystemOtherDetails: Yup.string(),
        conservationIssueChosenOptions: Yup.array()
            .min(1, "At least one conservation issue option is required")
            .required("At least one conservation issue option is required"),
        conservationIssueOtherDetails: Yup.string(),
        marineProtectedAreaChosenOption: Yup.number()
            .required("At least one marine protected area option is required"),
        marineProtectedAreaFurtherDetails: Yup.string(),
    }),
    consentDetails: Yup.object({
        isOptedInName: Yup.boolean(),
        isOptedInInstagram: Yup.boolean(),
        isOptedInFacebook: Yup.boolean(),
        isOptedInWebsite: Yup.boolean(),
        isOptedInPadiOrStoreNumber: Yup.boolean(),
        isOptedInForCommunications: Yup.boolean()
    }),
    recaptcha: Yup.object({
        recaptchaToken: Yup.string()
            .nullable()
            .required("Please complete the ReCAPTCHA form"),
    }),
    isUserConfirmed: Yup.boolean()
        .required("Please agree to the terms and conditions before you submit this form")
        .oneOf([true], "Please agree to the terms and conditions before you submit this form")
});