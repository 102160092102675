import { ISubmissionQuestionOptionBulkApiModel } from "clients/AdoptTheBlueClient";
import { AdoptTheBlueMetaDataTypeIds } from "common/viewModels";
import { IDonutChartStatItem } from "features/actionMap/actionMapSlice";
import { IFilterFormValues } from "../components/FilterSideBar/Index";

export interface IChosenOption {
    value: string,
    label: string
}

export function createChosenOptions(options?: ISubmissionQuestionOptionBulkApiModel[]) {

    const ret = options?.map(a => {
        return {
            value: a.id.toString(),
            label: a.name
        } as IChosenOption
    });

    return ret
}

export function createChosenOptionsMpa() {

    const ret: IChosenOption[] = [
        {
            value: "17",
            label: "Protected"
        },
        {
            value: "18",
            label: "Unprotected"
        },
        {
            value: "19",
            label: "Pending"
        },
        {
            value: "16",
            label: "Unknown"
        }
    ];

    return ret
}

export const getSiteListStatusById = (siteListStats: IDonutChartStatItem[] | null, id: AdoptTheBlueMetaDataTypeIds) => siteListStats?.find(a => a.submissionQuestionId === id);

export const removeChosenOptionById = (id: string, values: IFilterFormValues, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {

    let chosenOptions: IChosenOption[] = [];
    let fieldName = "";

    if (values.chosenEcosystemOptions?.some(a => a.value === id)) {
        chosenOptions = values.chosenEcosystemOptions;
        fieldName = "chosenEcosystemOptions";
    }
    else if (values.chosenConservationIssueOptions?.some(a => a.value === id)) {
        chosenOptions = values.chosenConservationIssueOptions;
        fieldName = "chosenConservationIssueOptions";
    }
    else if (values.chosenMarineProtectionStatusOptions?.some(a => a.value === id)) {
        chosenOptions = values.chosenMarineProtectionStatusOptions;
        fieldName = "chosenMarineProtectionStatusOptions";
    }
    else {
        throw new Error('unknown chosenOptionId: ' + id);
    }

    chosenOptions = chosenOptions.filter(a => a.value !== id);

    setFieldValue(fieldName, chosenOptions);
};

export function httpsUrl(url?: string) {
    url = url?.trim() ?? "";

    if (!url)
        return url;

    if (url.toLowerCase().startsWith("https://"))
        return url;

    if (url.toLowerCase().startsWith("http://"))
        return 'https://' + url.substring(7);

    return 'https://' + url;
}

export function isFilterSideBarFiltered(values: IFilterFormValues): boolean {

    if (values.chosenConservationIssueOptions && values.chosenConservationIssueOptions.length) {
        return true;
    }
    
    if (values.chosenEcosystemOptions && values.chosenEcosystemOptions.length) {
        return true;
    }
    
    if (values.chosenMarineProtectionStatusOptions && values.chosenMarineProtectionStatusOptions.length) {
        return true;
    }
    
    return false;
}