import cc from "classcat";
import styled from "styled-components";

export const PadiContainer = styled(({ className, children }: any) => (
    <div className={cc(["container", className])}>
        {children}
    </div>
))`
    max-width: 754px;

    margin-bottom: 80px;

    @media (max-width: 767.98px) { 
        padding: 15px;
        border: 0;
        // border-top: 1px solid #dedede;
        // border-bottom: 1px solid #dedede;
    }

    @media (min-width: 768px) { 
        padding: 60px;
        border: 1px solid #dedede;
        border-radius: 4px;
    }
`;