import { FieldAttributes, useField, useFormikContext } from 'formik';
import Select from 'react-select'

type CustomSelectProps = FieldAttributes<{}> & {
    options: { value: string, label: string }[],
    label?: string;
};

function CustomSelect(props: CustomSelectProps) {

    const { setFieldValue, setFieldTouched } = useFormikContext();
    const [field] = useField(props);

    const { label, options, placeholder } = props;

    const fullId = props.id || `${props.name}`;

    return (
        <>
            {label && <label htmlFor={fullId} className="form-label">{label}</label>}

            <Select
                options={options}
                value={options ? options.find(option => option.value === field.value) : undefined}
                onChange={(option) => setFieldValue(field.name, option?.value)}
                onBlur={() => setFieldTouched("state", true)}
                placeholder={placeholder}
            />
        </>
    );
}

export default CustomSelect;