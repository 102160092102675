function Index() {
    return (
        <div className="container">
            <h1>Not Found</h1>

            <p>The page you were looking for could not be found!</p>
        </div>
    );
}

export default Index;