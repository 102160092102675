import styled from "styled-components";
import { Theme } from '../../common/Theme';

export const IconBubble = styled.div.attrs({
    className: 'd-flex align-items-center justify-content-evenly',
})`
    width: 48px;
    height: 48px;
    background: #0070D3;
    border-radius: 50%;
    color: white;
`;

export function ContactIcon({ name, link, icon }: {
    name: string;
    link: string;
    icon?: React.ReactNode,
}) {
    return (
        <a className="d-flex flex-column align-items-center text-decoration-none" href={link} target="_blank" rel="noreferrer">
            <IconBubble>
                {icon}
            </IconBubble>
            <div className="pt-1" style={{ color: Theme.colors.blueBrand, fontSize: "14px" }}>{name}</div>
        </a>
    );
}