import { AdoptTheBlueMetric } from 'clients/AdoptTheBlueClient';
import { IFormValues } from 'common/viewModels';
import { useFormikContext } from 'formik';

function EstimatedDiveSiteAreaSoftValidationMessage() {

    const formContext = useFormikContext<IFormValues>();
    const { values } = formContext;

    const area = values.siteDetails.estimatedDiveSiteArea;
    const metric = values.siteDetails.estimatedDiveSiteAreaMetric;

    // const messageMetresFull = "The area surveyed is greater than 13,600 m² (two soccer pitches) or less than 10 m² (a parking spot)";
    const messageMetresUpper = "The area surveyed is greater than 13,600 m² (two soccer pitches)";
    const messageMetresLower = "The area surveyed is less than 10 m² (a parking spot)";

    // const messageFeetFull = "The area surveyed is greater than 146,388 ft² (two soccer pitches) or less than 110 ft² (a parking spot)";
    const messageFeetUpper = "The area surveyed is greater than 146,388 ft² (two soccer pitches)";
    const messageFeetLower = "The area surveyed is less than 110 ft² (a parking spot)";

    if (!area || !metric) {
        return null;
    }

    let error: string | null = null;

    if (metric === AdoptTheBlueMetric.Metres) {

        if (area > 13600) {
            error = messageMetresUpper;
        } else if (area < 10) {
            error = messageMetresLower;
        }
    }

    if (metric === AdoptTheBlueMetric.Feet) {

        if (area > 146388) {
            error = messageFeetUpper;
        } else if (area < 110) {
            error = messageFeetLower;
        }
    }

    if (!error) {
        return null;
    }

    return <p className="text-muted d-block">{error}</p>;
}

export default EstimatedDiveSiteAreaSoftValidationMessage;