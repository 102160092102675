import { MapContainer, TileLayer, Marker, useMapEvents, useMap } from "react-leaflet";
import { LatLng } from "leaflet";

interface MapCoordSelectorProps {
    onCoordinatesSelected: (coords: LatLng) => void;
    location: LatLng;
}

function MapCoordSelectorClick(props: MapCoordSelectorProps) {

    const map = useMapEvents({
        click(e) {
            props.onCoordinatesSelected(e.latlng);
            map.setView(e.latlng, map.getZoom(), {
                animate: true,
            })
        },
    });

    return null;
}

function ChangeView({ center }: {
    center: LatLng;
}) {
    const map = useMap();

    map.setView(center, map.getZoom(), {
        animate: true
    });

    return null;
}

function MapCoordSelector({ location, onCoordinatesSelected }: MapCoordSelectorProps) {

    return (
        <MapContainer center={location} zoom={13} style={{ width: "100%", height: "300px", cursor: "pointer" }}>
            <TileLayer
                // attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}"
                subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
            />
            <MapCoordSelectorClick onCoordinatesSelected={onCoordinatesSelected} location={location} />
            {location && <Marker position={location} />}
            <ChangeView center={location} />
        </MapContainer>
    );
}

export default MapCoordSelector;