import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { ILoadStatus, LoadStatusEnum } from 'features/common/LoadStatus';
import { StandardReject } from 'features/common/StandardReject';
import { RootState } from 'app/store';

import {
    AdoptTheBlueResponseApiModel,
    AdoptTheBlueSubmissionApiModel,
    ICustomValidationError,
    IAdoptTheBlueSubmissionApiModel,
    ISubmissionQuestionBulkApiModel,
    IUrlsBulkApiModel
} from 'clients/AdoptTheBlueClient';

import { BulkClientInstance } from 'clients/BulkClientInstance';
import { ToViewModel } from 'common/mapping/toViewModel';
import { AdoptTheBlueClientInstance } from 'clients/AdoptTheBlueClientInstance';
import { AppConfig } from 'common/config';

export interface ISystemDataApiModel extends ILoadStatus {
    submissionQuestions?: ISubmissionQuestionBulkApiModel[];
    urls?: IUrlsBulkApiModel;
}

export interface IRequestToAdoptTheBlueStatus extends ILoadStatus {
    validationErrors?: ICustomValidationError[]
}

export type AdoptTheBlueState = {
    systemData?: ISystemDataApiModel,
    requestToAdoptTheBlueStatus: IRequestToAdoptTheBlueStatus,
};

const initialState: AdoptTheBlueState = {
    systemData: undefined,
    requestToAdoptTheBlueStatus: {
        loadStatus: LoadStatusEnum.Idle
    }
};

// Thunks

export const requestToAdoptTheBlue = createAsyncThunk<AdoptTheBlueResponseApiModel, IAdoptTheBlueSubmissionApiModel, { rejectValue: StandardReject }>
    ('adoptTheBlueFormSlice/requestToAdoptTheBlue', async (adoptTheBlueSubmissionApiModel: IAdoptTheBlueSubmissionApiModel, { rejectWithValue }) => {
        try {
            const apiClient = AdoptTheBlueClientInstance.create();

            let response = await apiClient.AdoptTheBlueClient.requestToAdoptTheBlue(new AdoptTheBlueSubmissionApiModel(adoptTheBlueSubmissionApiModel));

            return response;

        } catch (err: any) {

            return rejectWithValue({
                status: err.status,
                validationErrors: err.validationErrors,
                errorMessage: err.errorMessage
            } as StandardReject)
        }
    });

export const fetchSystemData = createAsyncThunk<ISystemDataApiModel>('adoptTheBlueFormSlice/fetchSystemData', async () => {

    var bulkClient = BulkClientInstance.create();

    var systemData = await bulkClient.BulkClient.getSystemData();

    var ret: ISystemDataApiModel = {
        submissionQuestions: ToViewModel.SubmissionQuestionArray(systemData.submissionQuestions),
        urls: ToViewModel.Urls(systemData.urls)
    };

    return ret;
})

// Slice

const adoptTheBlueFormSlice = createSlice({
    name: 'userSession',
    initialState: initialState,
    reducers: {

    },
    extraReducers: builder => {
        builder
            // fetchSystemData
            .addCase(fetchSystemData.pending, (state, action) => {
                state.systemData = {
                    loadStatus: LoadStatusEnum.Loading
                };
            })
            .addCase(fetchSystemData.fulfilled, (state, action) => {

                state.systemData = action.payload;
                state.systemData.loadStatus = LoadStatusEnum.Completed;

                return state;
            })
            .addCase(fetchSystemData.rejected, (state, action) => {
                state.systemData = {
                    loadStatus: LoadStatusEnum.Error,
                    errorMessage: action.error.message
                };
            })

            // requestToAdoptTheBlue
            .addCase(requestToAdoptTheBlue.pending, (state, action) => {
                state.requestToAdoptTheBlueStatus = {
                    loadStatus: LoadStatusEnum.Loading
                };
            })
            .addCase(requestToAdoptTheBlue.fulfilled, (state, action) => {

                // alert("Saved: " + action.payload.uId);

                state.requestToAdoptTheBlueStatus = {
                    loadStatus: LoadStatusEnum.Completed
                }

                return state;
            })
            .addCase(requestToAdoptTheBlue.rejected, (state, action) => {

                const requestToAdoptTheBlueValidationErrors = action.payload?.validationErrors.map(a => {
                    return {
                        propertyName: a.propertyName,
                        errors: a.errors
                    }
                });

                state.requestToAdoptTheBlueStatus = {
                    validationErrors: requestToAdoptTheBlueValidationErrors,
                    loadStatus: LoadStatusEnum.Error,
                    errorMessage: action.error.message
                }
            })
    }
})

// Selectors

type Selector<S> = (state: RootState) => S;

export const getSubmissionQuestionByIdSelector = (id: number): Selector<ISubmissionQuestionBulkApiModel | undefined> =>
    createSelector(
        [(state: RootState) => state.adoptTheBlueReducer.systemData?.submissionQuestions?.find(a => a.id === id)],
        (item) => item
    );

export const getSystemDataLoadStatusSelector = createSelector(
    (state: RootState) => state,
    (state) => {
        return {
            loadStatus: state.adoptTheBlueReducer.systemData?.loadStatus,
            errorMessage: state.adoptTheBlueReducer.systemData?.errorMessage
        } as ILoadStatus
    }
);

export const getSystemDataUrlsSelector = createSelector(
    (state: RootState) => state,
    (state) => state.adoptTheBlueReducer.systemData?.urls
);

export const getIsDebugModeEnabledSelector = createSelector(
    (state: RootState) => state,
    (state) => AppConfig.Settings.isDebugModeEnabled
);

export const getRequestToAdoptTheBlueStatusSelector = createSelector(
    (state: RootState) => state,
    (state) => state.adoptTheBlueReducer.requestToAdoptTheBlueStatus
);

// export const {

// } = adoptTheBlueSlice.actions;   

export default adoptTheBlueFormSlice.reducer;