import styled from "styled-components";
import PopupContainer from "./PopupContainer";

interface FilterPopUpProps {
    isFiltered: boolean;
    numResultsTotal: number;
    numResultsFiltered: number;
    areaTotal: number;
    areaFiltered: number;
}

const FilterPopUpContainerInner = styled.div<FilterPopUpProps>`
    padding: 14px 24px;
    border-top: 4px solid ${(props) => props.isFiltered ? props.theme.colors.blueBrand : props.theme.colors.greyLight};
    transition: all 0.2s ease-in-out;

    .info-title {
        color: ${(props) => props.theme.colors.grayDarkest};
    }

    .info-text {
        color: ${(props) => props.isFiltered ? props.theme.colors.blueBrand : props.theme.colors.grayDarker};
        transition: color 0.5s ease-in-out;
    }

    .info-percentage {
        color: ${(props) => props.isFiltered ? props.theme.colors.blueBrand : props.theme.colors.grayDarker};
        opacity: ${(props) => props.isFiltered ? "1.0" : "0.0"};
        transition: 
            color 0.5s ease-in-out, 
            opacity 0.3s ease-in-out;
    }    

    .totals-filtered-title {
        opacity: ${(props) => props.isFiltered ? "1.0" : "0.0"};
        transition: opacity 0.3s ease-in-out;       
    }
`;

function FilterInfoPopUp(props: FilterPopUpProps) {

    const {
        isFiltered,
        numResultsTotal,
        numResultsFiltered,
        areaTotal,
        areaFiltered,
    } = props;

    const numResultsFilteredAsPercentage = (numResultsTotal > 0 ? numResultsFiltered / numResultsTotal * 100 : 0).toFixed(0);
    const areaFilteredAsPercentage = (areaTotal > 0 ? areaFiltered / areaTotal * 100 : 0).toFixed(0);

    return <PopupContainer>
        <FilterPopUpContainerInner {...props}>
            <div className="fw-bold">Totals <span className="totals-filtered-title">· Filtered</span></div>
            <div className="d-flex justify-content-between gap-3 mt-2">
                <div>
                    <span className="info-title">Adopted Sites:</span>
                    <br />
                    <span className="info-text">{numResultsFiltered}</span>
                    <span className="info-percentage">{" "}{isFiltered && `(${numResultsFilteredAsPercentage}%)`}</span>
                </div>
                <div className="vr"></div>
                <div>
                    <span className="info-title">Area of Sites:</span>
                    <br />
                    <span className="info-text">{Math.round(areaFiltered/1000000).toLocaleString('en')}km²</span>
                    <span className="info-percentage">{" "}{isFiltered && `(${areaFilteredAsPercentage}%)`}</span>
                </div>
            </div>
        </FilterPopUpContainerInner>
    </PopupContainer>;
}

export default FilterInfoPopUp;