import styled, { css } from "styled-components";
import { Property } from 'csstype';
export type Location = 'none' | 'topleft' | 'topright' | 'bottomleft' | 'bottomright';

interface PositionContainerProps {
    location: Location;
    position?: Property.Position | undefined;
    top?: number;
    right?: number | string;
    bottom?: number;
    left?: number;
    width?: number | string;
    zIndex?: number;
}

const PositionContainer = styled.div<PositionContainerProps>`
    width: ${(props) => props.width ? `${props.width}px` : "auto"};
    z-index: ${(props) => props.zIndex ? `${props.zIndex}` : "100"};
    position: ${(props) => props.position ?? "static"};

    ${(props) => {
        switch (props.location) {
            case "topleft":
                return css`
                    top: ${props.top ?? 25}px;
                    left: ${props.left ?? 25}px;
                `;
            case "topright":
                return css`
                    top: ${props.top ?? 25}px;
                    right: ${props.right ?? 25}px;
                `;
            case "bottomleft":
                return css`
                    bottom: ${props.bottom ?? 25}px;
                    left: ${props.left ?? 25}px;
                `;
            case "bottomright":
                return css`
                    bottom: ${props.bottom ?? 25}px;
                    right: ${props.right ?? 25}px;
                `;
        }
    }}
`;

export default PositionContainer;