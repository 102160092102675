import { CustomRadioButton } from 'common/components/CustomRadioButton';
import { IFormValues } from 'common/viewModels';
import { useFormikContext } from 'formik';
import { CustomTextbox } from 'common/components/CustomTextbox';
import { AdoptTheBlueMetric } from 'clients/AdoptTheBlueClient';
import { createSiteNameFullPreview } from 'common/helpers';
import CustomErrorMessage from 'common/components/CustomErrorMessage';
import CustomNumberField from 'common/components/CustomNumberField';
import MapCoordSelectorForm from './MapCoordSelectorForm';
import { useEffect, useState } from 'react';
import { LatLng } from 'leaflet';
import EstimatedDiveSiteAreaSoftValidationMessage from './EstimatedDiveSiteAreaSoftValidationMessage';

function Step2() {

    const formContext = useFormikContext<IFormValues>();
    const { values, setFieldValue } = formContext;
    const [defaultLocation] = useState<LatLng>(new LatLng(51.505, -0.09));

    useEffect(() => {

        if (!values.siteDetails.location?.latitude && !values.siteDetails.location?.longitude) {

            navigator.geolocation.getCurrentPosition(function (position) {
                setFieldValue("siteDetails.location.latitude", position.coords.latitude);
                setFieldValue("siteDetails.location.longitude", position.coords.longitude);
            }, (e) => {
                console.log(e);
            });
    
        }

    }, [setFieldValue])

    return (
        <>
            <div className="mb-5">

                <p className="fw-bold">Site Name <span className="text-danger">*</span></p>

                <p>
                    Choose a name for your Adopt The Blue Site. This will be your site location plus your PADI store/member number.
                    For example, Dolphin Cove S311104 or Wreck Reef #15137.
                    This name will be displayed on the public map on padiaware.org.
                </p>

                <div className="mb-3">
                    <CustomTextbox name="siteDetails.diveLocation" placeholder="Site Location" />
                    <CustomErrorMessage name="siteDetails.diveLocation" />
                </div>
                <div className="mb-3">
                    <CustomTextbox name="siteDetails.siteNamePreview" value={createSiteNameFullPreview(values)} placeholder="Dive Site Name" disabled />
                </div>

            </div>

            <div className="mb-5">

                <div>
                    <p className="fw-bold">Site Coordinates <span className="text-danger">*</span></p>

                    <p>Click the location of your dive site using the map</p>

                    <MapCoordSelectorForm defaultLocation={defaultLocation} />
                </div>

            </div>

            <div className="mb-5">

                <p className="fw-bold">Estimated Dive Site Area <span className="text-danger">*</span></p>

                <div className="mb-3 row">
                    <div className='col'>
                        <div className="input-group">
                            <CustomNumberField name="siteDetails.estimatedDiveSiteArea" placeholder="Estimated Dive Site Area" />
                            <span className="input-group-text">
                                {values.siteDetails.estimatedDiveSiteAreaMetric === AdoptTheBlueMetric.Feet ? "ft²" : "m²"}
                            </span>
                        </div>
                        <CustomErrorMessage name="siteDetails.estimatedDiveSiteArea" />
                    </div>
                    <div className="col d-none d-sm-block">
                        &nbsp;
                    </div>
                </div>

                <EstimatedDiveSiteAreaSoftValidationMessage />

                <div className="mb-3">
                    <div className="d-flex flex-md-row flex-column gap-3">
                        <CustomRadioButton name="siteDetails.estimatedDiveSiteAreaMetric" value={AdoptTheBlueMetric.Feet} label="Feet²" />
                        <CustomRadioButton name="siteDetails.estimatedDiveSiteAreaMetric" value={AdoptTheBlueMetric.Metres} label="Meters²" />
                    </div>
                    <CustomErrorMessage name="siteDetails.estimatedDiveSiteAreaMetric" />
                </div>

            </div>
        </>
    );
}

export default Step2;