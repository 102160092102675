import { useFormikContext } from 'formik';
import styled from 'styled-components';

import { isFilterSideBarFiltered } from 'pages/Map/common/helper';
import { IFilterFormValues } from '../Index';

interface IBottomControlsSubProps {
    isCollapsed: boolean;
}

interface IBottomControlsProps extends IBottomControlsSubProps {
    onResetForm: (setFieldValue: any) => void;
    onToggleVisibility: () => void;
}

const ButtonControlsContainer = styled.div<IBottomControlsSubProps>`
    background: #FFFFFF;
    width: 100%;

    @media (max-width: 767.98px) { 
        box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
        position: fixed; 
        bottom: 0; 
        right: ${props => props.isCollapsed ? "calc(-768px)" : "0px"};
        padding: 18px 15px 18px 15px;
        z-index: ${props => props.theme.zOrder.filterButtons};
        transition: right 0.5s ease-in-out;
    }

    .btn:focus,
    .btn:active {
        outline: none !important;
        box-shadow: none !important;
     }

    .btn {
        box-shadow: none !important
    }
`;

function BottomControls(props: IBottomControlsProps) {

    const { values, setFieldValue } = useFormikContext<IFilterFormValues>();
    const { onResetForm, onToggleVisibility, ...rest } = props;

    return (
        <ButtonControlsContainer {...rest}>
            <div className="d-grid gap-2 d-flex justify-content-center d-block">
                <button
                    className="btn btn-outline-primary btn-sm w-100 text-uppercase"
                    type="button"
                    onClick={() => props.onResetForm(setFieldValue)}
                    disabled={!isFilterSideBarFiltered(values)}
                >
                    Clear Filters
                </button>
                <button className="btn btn-primary btn-sm btn-block w-100 text-uppercase d-block d-md-none" type="button" onClick={() => props.onToggleVisibility()}>View Results</button>
            </div>
        </ButtonControlsContainer>
    );
}

export default BottomControls;