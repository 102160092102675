
import { Field, FieldAttributes, useFormikContext } from "formik";
import { isInvalid } from "./helpers";
import cc from "classcat";

const getFieldClasses = (className?: string, isFieldInvalid?: boolean) => {
    return cc([
        "form-check-input",
        {
            "is-invalid": isFieldInvalid
        },
        className
    ]);
}

type CustomRadioButtonProps = FieldAttributes<{}> & {
    label?: string;
};

export function CustomRadioButton(props: CustomRadioButtonProps) {

    const { errors, touched } = useFormikContext();
    const { id, label, className, ...rest } = props;

    const fullId = props.id || `${props.name}_${props.value}`;

    return (
        <div className="form-check">
            <Field type="radio" id={fullId} value={props.value} className={getFieldClasses(className, isInvalid(props.name, errors, touched))} {...rest} />
            <label className="form-check-label" htmlFor={fullId}>
                {props.label}
            </label>
        </div>
    );
}