import { AdoptTheBlueMemberType } from 'clients/AdoptTheBlueClient';
import * as ViewModels from 'common/viewModels';
import * as ApiModels from 'clients/AdoptTheBlueClient';

export const getPadiPrefixSymbol = (values: ViewModels.IFormValues): string => {

    if (values.userDetails.memberType === AdoptTheBlueMemberType.Individual) {
        return "#";
    }
    else if (values.userDetails.memberType === AdoptTheBlueMemberType.Business) {
        return "S";
    }

    return "";
}

export const getPadiMembershipOrStoreNumber = (values: ViewModels.IFormValues): string => {

    if (values.userDetails.memberType === AdoptTheBlueMemberType.Individual) {
        return values.userDetails.padiMembershipNumber ?? "";
    }
    else if (values.userDetails.memberType === AdoptTheBlueMemberType.Business) {
        return values.userDetails.padiStoreNumber ?? "";
    }

    return "";
};

export const getFullNameOrOrgName = (values: ViewModels.IFormValues): string => {

    if (values.userDetails.memberType === AdoptTheBlueMemberType.Individual) {
        return (values.userDetails.lastName ?? "").trim();
    }
    else if (values.userDetails.memberType === AdoptTheBlueMemberType.Business) {
        return (values.userDetails.organisationName ?? "").trim();
    }

    return "";
};

export const isFreeTextOptionSelected = (question?: ApiModels.ISubmissionQuestionBulkApiModel, chosenOptions?: number[] | number): boolean => {

    var chosenOptionsArr = (chosenOptions instanceof Array) ? chosenOptions : [chosenOptions];

    if (!chosenOptionsArr?.length || !question?.freeTextOptionId) {
        return false;
    }

    if (chosenOptionsArr.some(a => a === question.freeTextOptionId)) {
        return true;
    }

    return false;
};


export const createSiteNameFullPreview = (values: ViewModels.IFormValues): string => `${values.siteDetails.diveLocation ?? ""} ${getPadiPrefixSymbol(values)}${getPadiMembershipOrStoreNumber(values)}`;