export const Theme = {
    colors: {
        grayDarkest: "#002547",
        grayDarker: "#657581",
        greyLight: "#D7DEE4",
        blueBrand: "#0070D3",
        white: "#ffffff"
    },
    shades: {
        green: [
            '#5d9f34', // Base Green Colour
            '#68a642', '#74ac50',
            '#7fb35f', '#8aba6d',
            '#96c17b', '#a1c789',
            '#acce97', '#b8d5a6',
            '#c3dbb4', '#cee2c2',
            '#dae9d0', '#e5f0df',
            '#f0f6ed', '#fcfdfb'
        ],
        red: [
            '#74151e', // Base Red Colour
            '#7e252e', '#87363e',
            '#91464d', '#9b575d',
            '#a5676d', '#ae777d',
            '#b8888c', '#c2989c',
            '#cca8ac', '#d5b9bc',
            '#dfc9cb', '#e9dadb',
            '#f2eaeb', '#fcfafb'
        ],
        orange: [
            '#e86100', // Base Orange Colour
            '#ea6c12', '#eb7724',
            '#ed8236', '#ee8d47',
            '#f09859', '#f2a36b',
            '#f3ae7d', '#f5b98f',
            '#f6c5a1', '#f8d0b3',
            '#fadbc4', '#fbe6d6',
            '#fdf1e8', '#fffcfa'
        ],
        blue: [
            '#00A7E1', // Base Blue Colour
            '#12ade3', '#24b3e5',
            '#36b9e7', '#47c0e9',
            '#59c6ec', '#6bccee',
            '#7dd2f0', '#8fd8f2',
            '#a1def4', '#b3e5f6',
            '#c4ebf8', '#d6f1fa',
            '#e8f7fc', '#fafdfe'
        ]
    },
    // Mostly here because chartjs doesn't behave too well with z-indexing
    zOrder: {
        chart: 100,
        dropdown: 201,
        filterButtons: 203,
        filterToggleButton: 203,
        markerPopupDetails: 204,
        filterSideBar: 205
    }
}