import { AdoptTheBlueMemberType, AdoptTheBlueMetric } from "clients/AdoptTheBlueClient";

export interface DebrisData {
    nid: string;
    title: string;
    duration: string;
    date: string;
    timestamp: number;
    diveCenter: null | string;
    teamLeader: null | string;
    numParticipants: string;
    ecosystem: Ecosystem | null;
    totalWeight: null | string;
    totalWeightMetric: TotalWeightMetric | null;
    totalWeightReliability: TotalWeightReliability | null;
    linkedUser: string;
    entangled: null | string;
    debris: DebrisDataInfo;
    lat: number;
    lng: number;
}

export interface DebrisDataInfo {
    entangled: number,
    plastic: number,
    glass: number,
    metal: number,
    rubber: number,
    wood: number,
    cloth: number,
    paper: number,
    mixed: number,
    other: number,
    total: number,
    debrisfree: number,
    ads: number
}

export enum Ecosystem {
    CoralReef = "Coral reef",
    EcosystemCoralReef = "coral reef",
    EcosystemRockyReef = "rocky reef",
    Kelp = "kelp",
    Mangroves = "mangroves",
    Other = "other",
    PurpleCoralReef = "Coral Reef",
    RockyReef = "Rocky reef",
    Seagrass = "seagrass",
}

export enum TotalWeightMetric {
    Kgs = "kgs",
    Lbs = "lbs",
}

export enum TotalWeightReliability {
    Estimated = "estimated",
    Measured = "measured",
}

export interface IAdoptTheBlueUserAddressDetailsApiModel {
    country?: string;
}

export interface IGeoLocationApiModel {
    latitude?: number;
    longitude?: number;
}

export interface IAdoptTheBlueUserDetailsApiModel {
    username?: string;
    memberType?: AdoptTheBlueMemberType;
    padiMembershipNumber?: string | undefined;
    padiStoreNumber?: string | undefined;
    firstName?: string | undefined;
    lastName?: string | undefined;
    emailAddress?: string;
    organisationName?: string | undefined;
    websiteUrl?: string | undefined;
    socialMediaFacebook?: string | undefined;
    socialMediaInstagram?: string | undefined;
    address: IAdoptTheBlueUserAddressDetailsApiModel;
}

export interface IAdoptTheBlueSiteDetailsApiModel {
    diveLocation?: string;
    estimatedDiveSiteArea?: number;
    estimatedDiveSiteAreaMetric?: AdoptTheBlueMetric;
    location?: IGeoLocationApiModel;
    ecosystemChosenOptions?: number[];
    ecosystemOtherDetails?: string | undefined;
    conservationIssueChosenOptions?: number[] | string[];
    conservationIssueOtherDetails?: string | undefined;
    marineProtectedAreaChosenOption?: number;
    marineProtectedAreaFurtherDetails?: string | undefined;
}

export interface IAdoptTheBlueConsentDetailsApiModel {
    isOptedInName?: boolean;
    isOptedInInstagram?: boolean;
    isOptedInFacebook?: boolean;
    isOptedInWebsite?: boolean;
    isOptedInPadiOrStoreNumber?: boolean;
    isOptedInForCommunications?: boolean;
}

export interface IReCaptchaRequest {
    recaptchaToken: string | null;
}

export interface IFormValues {
    userDetails: IAdoptTheBlueUserDetailsApiModel;
    siteDetails: IAdoptTheBlueSiteDetailsApiModel;
    consentDetails: IAdoptTheBlueConsentDetailsApiModel;
    recaptcha: IReCaptchaRequest;
    isUserConfirmed: boolean;
}

export interface ISubmissionQuestion {
    id: number;
    freeTextOptionId?: number | undefined;
    name: string;
    description: string;
    options: ISubmissionQuestionOption[];
}

export interface ISubmissionQuestionOption {
    id: number;
    submissionQuestionId: number;
    name: string;
    sortOrder: number;
}

export enum AdoptTheBlueMetaDataTypeIds {
    Ecosystem = 1,
    ConservationIssues = 2,
    MarineProtected = 3
}

export interface IUrlsBulkApiModel {
    actionMap: string;
    adoptTheBlueFAQs: string;
    adoptTheBlueToolkit: string;
    currentActions: string;
    diveAgainstDebrisToolkit: string;
    makeADonation: string;
    ourConservationStrategy: string;
    privacyPolicy: string;
    whyParticipate: string;
}