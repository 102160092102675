import { Field, FieldAttributes, useFormikContext } from "formik";
import cc from "classcat";
import { isInvalid } from "./helpers";

const getFieldClasses = (isFieldInvalid?: boolean) => {
    return cc([
        "form-control",
        {
            "is-invalid": isFieldInvalid
        }
    ]);
}

type CustomTextboxProps = FieldAttributes<{}> & {
    label?: string;
};

export function CustomTextbox(props: CustomTextboxProps) {

    const { errors, touched } = useFormikContext();
    const { id, label, ...rest } = props;

    const fullId = props.id || `${props.name}`;

    return (
        <>
            {label && <label htmlFor={fullId} className="form-label">{label}</label>}
            <Field type="text" id={fullId} className={getFieldClasses(isInvalid(props.name, errors, touched))}{...rest} />
        </>
    );
}