import { FieldAttributes, useField, useFormikContext } from 'formik';
import { Theme } from 'pages/Map/common/Theme';
import React from 'react';
import Select, { components, StylesConfig } from 'react-select';

interface IChosenOption {
    value: string,
    label: string
}

const Option = (props: any) => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />
                {" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

const CustomValueContainer = ({ label, children, ...props }: any) => {
    const { getValue } = props;
    const numValues = getValue().length;

    let displayName = label;

    if (numValues > 0) {
        displayName += ` (${numValues})`;
    }

    return (
        <components.ValueContainer {...props}>
            {displayName}

            {/* Note: This is here to reinclude the element that click handlers are added to, see long standing issues
            https://github.com/JedWatson/react-select/issues/2597 but never actually solved. The ID "clickableInput" is
            specified on component initialization i.e. <Select inputId='clickableInput' /> */}
            {React.Children.map(children, (child) => {
                return child.props.id === 'clickableInput' ? child : null;
            })}
        </components.ValueContainer>
    );
};

type CustomMultiSelectProps = FieldAttributes<{}> & {
    values?: IChosenOption[],
    options?: { value: string, label: string }[],
    label?: string;
    valueContainerLabel?: string;
};

const selectStyles: StylesConfig<IChosenOption, true> = {
    valueContainer: (base) => ({
        ...base,
        display: "block"
    }),
    control: (base, state) => ({
        ...base,
        background: state.hasValue ? Theme.colors.blueBrand : Theme.colors.white,
        color: state.hasValue ? Theme.colors.white : Theme.colors.grayDarkest,
        fontSize: "16px",
        lineHeight: "24px",
        fontWeight: 700
    }),
    option: (base, { data, isDisabled, isFocused, isSelected }) => ({
        ...base,
        background: "transparent",
        color: Theme.colors.grayDarkest,
        width: '100%',
    }),
    dropdownIndicator: (base, state) => ({
        ...base,
        color: state.hasValue ? Theme.colors.white : Theme.colors.grayDarkest,
        fontWeight: 400,

        "&:hover": {
            color: state.hasValue ? Theme.colors.greyLight : Theme.colors.grayDarker,
        }
    }),
    menu: (base) => ({
        ...base,
        zIndex: Theme.zOrder.dropdown
    })
};

function CustomMultiSelect(props: CustomMultiSelectProps) {

    const { setFieldValue, setFieldTouched } = useFormikContext();
    const [field] = useField(props);

    const { label, options, placeholder } = props;

    const fullId = props.id || `${props.name}`;

    return (
        <>
            {label && <label htmlFor={fullId} className="form-label">{label}</label>}

            <Select
                inputId='clickableInput'
                className="w-100"
                options={options}
                isClearable
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                styles={selectStyles}
                openMenuOnClick={true}
                isSearchable={false}
                components={{
                    Option,
                    ValueContainer: (inputProps: any) => (
                        <CustomValueContainer label={props.valueContainerLabel} {...inputProps} />
                    ),
                    IndicatorSeparator: () => null,
                    CrossIcon: () => null,
                    ClearIndicator: () => null
                }}
                value={props.values}
                onChange={(option) => {
                    setFieldValue(field.name, option);
                    setFieldTouched(field.name, true, false);
                }}
                onBlur={field.onBlur}
                placeholder={placeholder}
            />
        </>
    );
}

export default CustomMultiSelect;