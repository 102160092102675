import { IFormValues } from "common/viewModels";

function createInitialFormValues(): IFormValues {
    var ret: IFormValues = {
        siteDetails: {
            diveLocation: '',
            estimatedDiveSiteArea: undefined,
            estimatedDiveSiteAreaMetric: undefined,
            location: {
                latitude: undefined,
                longitude: undefined
            },
            ecosystemChosenOptions: undefined,
            ecosystemOtherDetails: undefined,
            conservationIssueChosenOptions: undefined,
            conservationIssueOtherDetails: undefined,
            marineProtectedAreaChosenOption: undefined,
            marineProtectedAreaFurtherDetails: undefined,
        },
        userDetails: {
            username: undefined,
            memberType: undefined,
            padiMembershipNumber: '',
            padiStoreNumber: '',
            firstName: '',
            lastName: '',
            emailAddress: '',
            organisationName: '',
            websiteUrl: '',
            socialMediaFacebook: '',
            socialMediaInstagram: '',
            address: {
                country: undefined
            },
        },
        consentDetails: {
            isOptedInName: undefined,
            isOptedInInstagram: undefined,
            isOptedInFacebook: undefined,
            isOptedInWebsite: undefined,
            isOptedInPadiOrStoreNumber: undefined,
            isOptedInForCommunications: undefined,
        },
        recaptcha: {
            recaptchaToken: ''
        },
        isUserConfirmed: false
    };

    return ret;
}

export default createInitialFormValues;