import { IconGeneralDataOptions } from '../../Icons';
import PopupContainer from '../../PopupContainer';

interface IFilterToggleButtonProps {
    isCollapsed: boolean;
    onToggleVisibility: () => void;
}

function FilterToggleButton(props: IFilterToggleButtonProps) {
    return (
        <PopupContainer>
            <div className="d-flex justify-content-between align-items-center px-4 py-3">
                <div>Data Options</div>
                <span className="d-flex align-items-center cursor-pointer" onClick={() => props.onToggleVisibility()}>
                    <IconGeneralDataOptions />
                </span>
            </div>
        </PopupContainer>
    );
}

export default FilterToggleButton;